import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import styles from './Opintopolkuun.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Opintopolkuun = ({ url }) => {
  const location = useLocation()
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing.opintopolkuun'})
  return (
    <Row className={`my-2 justify-content-between ${styles.element}`}>
      { location.pathname !== '/lukioon' &&
          <Button target='_blank' rel="noreferral noopener" href={url} className="button-primary" >
            {t('cta1')}
          </Button>
      }
      <Button as={Link} to="/feedback" className="button-secondary">{t('cta2')}</Button>
    </Row>
  )
}

Opintopolkuun.propTypes = {
  url: PropTypes.string.isRequired
}

export default Opintopolkuun
