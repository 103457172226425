import React, { useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { MOBILE_BREAKPOINT, YEARS_HIGHSCHOOL } from '../../../../constants/constants'
import styles from './HighschoolCard.module.css'
import HighschoolContent from './HighschoolContent'
import MobileCardHighschool from './MobileCardHighschool'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createSubjectcardClickEvent } from '../../../../utils/analytics'
import { useTranslation } from 'react-i18next'

const CustomToggle = ({
  children, eventKey, cardId
}) => {

  const decoratedOnClick = useAccordionButton(eventKey)

  const handleClick = () => {
    createSubjectcardClickEvent(cardId)
    decoratedOnClick()
  }
  
  return (
    <div className={styles.showMore}>
      <Button
        className="button-secondary"
        onClick={handleClick}
      >
        {children}
      </Button>
    </div>
  )
}

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
  cardId: PropTypes.number.isRequired
}

const HighschoolCard = props => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const [mobile, setWindowSize] = useState(getWindowSize())
  const [mobileOpen, setMobileOpen] = useState(false)
  const [expanded, setExpand] = useState(false)

  const gpa = useSelector(state => state.diplom.gpa)
  
  const themeChooser = year => {
    if (props.program.special) {
      return styles.noDiplom
    }
    if (gpa === null || !props.program.minimumPoints[0][`year_${year}`]) {
      return styles.diplomNotSet
    }
    if (gpa >= props.program.minimumPoints[0][`year_${year}`]) {
      return styles.gotIn
    } else return styles.notIn
  }
  
  const iconChooser = year => {
    if (props.program.special) {
      return 'brush-fill'
    }
    if (gpa === null || !props.program.minimumPoints[0][`year_${year}`]) {
      return 'question-circle-fill'
    }
    if (gpa >= props.program.minimumPoints[0][`year_${year}`]) {
      return 'check-circle-fill'
    } else return 'x-circle-fill'
  }
  
  const handleOffcanvasOpening = () => {
    if (mobile) {
      createSubjectcardClickEvent(props.program.id, gpa >= props.program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`], false)
      setMobileOpen(true)
    }
  }
  
  const handleClose = () => {
    setMobileOpen(false)
  }

  const handleExpand = () => {
    setExpand(!expanded)
  }
  
  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])


  return (
    <div className={styles.card} key={props.program.id}>
      <Accordion defaultActiveKey="0">
        <div onClick={handleOffcanvasOpening}>
          <div className={styles.cardRow}>
            <div className={styles.contentCol}>
              <h2 className="mb-2">
                {props.program.name}
              </h2>
              <div className={styles.location}>
                <i className="bi bi-geo-alt me-1 text-secondary"></i>
                <p className="mb-0">{props.program.school.location.name}</p>
              </div>
              {!mobile &&
                <div onClick={handleExpand} className={styles.toggleWrapper}>
                  <CustomToggle eventKey={props.program.name} cardId={props.program.id}>
                    <i className={`bi bi-caret-${expanded ? 'up' : 'down'}-fill text-primary me-2`}></i>
                    {expanded ? t('showLess') : t('showMore')}
                  </CustomToggle>
                </div>
              }
            </div>
            <div className={styles.iconCol}>
              {YEARS_HIGHSCHOOL.slice(0, 2).map(year => (
                <div className={`${styles.iconRow} ${themeChooser(year)}`} key={year}>
                  <i className={`bi bi-${iconChooser(year)} ${styles.headerIcon}`}></i>
                  <p className="mb-0">{year}</p>
                </div>
              ))
              }
            </div>
          </div>
          {!mobile &&
              <Accordion.Collapse eventKey={props.program.name}>
                <HighschoolContent program={props.program} />
              </Accordion.Collapse>
          }
        </div>
      </Accordion>
      {mobile &&
        <MobileCardHighschool
          open={mobileOpen}
          program={props.program}
          handleClose={handleClose}
          iconChooser={iconChooser}
          themeChooser={themeChooser}
        />
      }
    </div>
  )
}

HighschoolCard.propTypes = {
  program: PropTypes.object.isRequired
}

export default HighschoolCard
