import axios from 'axios'
import { BASE_URL, HIGHSCHOOLS_URL } from '../constants/constants'


const getHighSchoolPrograms = async () => {
  const response = await axios.get(BASE_URL + HIGHSCHOOLS_URL + 'programs/')
  return response.data
}


export default { getHighSchoolPrograms }
