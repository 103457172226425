import React, {useState, useEffect} from 'react'
import { Row, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { FIVE_SECONDS } from '../../../constants/constants'
import { setGPA } from '../../../reducers/diplomReducer'
import styles from './GpaInput.module.css'
import { useTranslation } from 'react-i18next'

const GpaInput = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.header.GPAInput'})
  const dispatch = useDispatch()
  const gpa = useSelector(state => state.diplom.gpa)

  const [newGpa, setNewGpa] = useState('')
  const [showAlert, setAlert] = useState(false)
  const [placeholder, setPlaceHolder] = useState(t('placeholder'))
  const [timeoutID, setTimeoutID] = useState(null)
  
  const handleEnter = e => {
    if(e.key === 'Enter'){
      e.preventDefault()
      handleClick()
    }
  }
  
  useEffect(() => {
    if(gpa !== null && placeholder !== gpa) {
      setPlaceHolder(gpa)
    }
  }, [gpa])
  
  const low = 4
  const up = 10

  const handleAlert = () => {
    if(timeoutID !== null){
      clearTimeout(timeoutID)
    }
    setAlert(true)
    const newTimeoutID = setTimeout(() => {
      setAlert(false)
      setTimeoutID(null)
    }, [FIVE_SECONDS])
    setTimeoutID(newTimeoutID)
  }
  const handleChange = e => {
    e.preventDefault()
    const newValue = e.target.value
    const parsed = newValue.replace(',', '.')
    if(parsed === '' || parsed === '1' || (!isNaN(+parsed) && parsed >=low && parsed <= up)){
      setNewGpa(parsed)
    }else{
      handleAlert()
    }
  }

  const handleClick = () => {
    const parsed = newGpa.replace(',', '.')
    if(!isNaN(+parsed) && parsed >=low && parsed <= up){
      dispatch(setGPA(parsed))
      setPlaceHolder(parsed)
      setNewGpa('')
      localStorage.setItem('DiplomGPA', JSON.stringify(parsed))
    }else{
      setNewGpa('')
      handleAlert()
    }
  }

  return (
    <>
      <Row className={styles.headingRow}>
        <h2>{t('title')}</h2>
      </Row>
      <Row className={styles.inputRow}>
        <input
          type='text'
          placeholder={placeholder}
          className={styles.search}
          value={newGpa}
          onChange={e => handleChange(e)}
          onKeyDown={handleEnter}
        />
        <div className={styles.buttonWrapper}>
          <Button onClick={handleClick} className="button-primary d-flex">
            {t('cta')}
          </Button>
        </div>
      </Row>
      {showAlert &&
        <div className={styles.alertRow}>
          <i className="bi bi-exclamation-triangle-fill d-flex me-2"></i>
          <p className="d-flex mb-0">{t('alert')}</p>
        </div>
      }
    </>
  )
}

export default GpaInput
