import React from 'react'
import styles from './Contact.module.css'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const { t } = useTranslation()

  return(
    <Row className={styles.contactRow}>
      <div className={styles.wrapper}>
        <h2 className="mb-3">{t('home.contactUs.title')}</h2>
        <p>{t('home.contactUs.description')}</p>
        <a href="mailto: frans.kangasniemi@trinode.fi" className="button-secondary">{t('home.contactUs.cta')}</a>
      </div>
    </Row>
  )
}

export default Contact
