import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import React, { useEffect, useState } from 'react'
import {
  Routes, Route, useLocation
} from 'react-router-dom'

import { useDispatch } from 'react-redux'

import NavBar from './components/Navbar'
import Footer from './components/Footer'
import Home from './components/Home'
import FeedbackPage from './components/FeedbackPage'
import CookieNotification from './components/CookieNotification'
import MobileNavigation from './components/MobileNavigation'
import PageBuilder from './components/PageBuilder'
import { initializeAll } from './reducers/initialize'
import Error404 from './components/404'
import { NAV_BREAKPOINT } from './constants/constants'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'


const App = () => {
  const { t, i18n } = useTranslation()

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= NAV_BREAKPOINT
  }

  const dispatch = useDispatch()
  const location = useLocation()

  const [collapsedNav, setWindowSize] = useState(getWindowSize())
  const [helmetText, setHelmetText] = useState('')

  useEffect(() => {
    switch(location.pathname) {
    case '/yliopistoon':
      setHelmetText('university')
      break
    case '/ammattikorkeaan':
      setHelmetText('amk')
      break
    case '/lukioon':
      setHelmetText('highschool')
      break
    case '/':
      setHelmetText('home')
      break
    case '/feedback':
      setHelmetText('feedback')
      break
    default:
      setHelmetText('error')
      break
    }
  }, [location.pathname])

  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }
  
  const [language, setLanguage] = useState(i18n.language)

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    setLanguage(lng)
  }

  useEffect(() => {
    changeLanguage('fi')
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    dispatch(initializeAll())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className="App">
      <Helmet>
        <title>{t(`helmet.${helmetText}`)}</title>
      </Helmet>
      <CookieNotification />
      <NavBar changeLanguage={changeLanguage} language={language} />
      <MobileNavigation />
      <div style={{marginTop: collapsedNav ? '65px' : '75px'}}>
        <Routes>
          <Route path='/yliopistoon' element={<PageBuilder />} />
          <Route path='/lukioon' element={<PageBuilder />} />
          <Route path='/ammattikorkeaan' element={<PageBuilder />} />
          <Route path='/feedback' element={<FeedbackPage />} />
          <Route exact path='/' element={<Home />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </div>
    </div>
  )
}

export default App
