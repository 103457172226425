import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { ONE_DAY_EXPIRY, ADS_AVAILABLE } from '../../../constants/constants'
import HankenAdvertisement from '../../static/images/annons_korkeakouluun_hanken.png'
import { setItemWithExpiryTime, getItemWithExpiry } from '../../../utils/localStorageExpiry'

const Advertisements = () => {
  const [show, setShow] = useState(false)

  const closeModal = () => {
    setShow(false)
    setItemWithExpiryTime('universityPageAdModal', 'true', ONE_DAY_EXPIRY)
  }

  useEffect(() => {
    const adModalShown = getItemWithExpiry('universityPageAdModal')
    if (ADS_AVAILABLE && (!adModalShown || adModalShown !== 'true')) {
      setShow(true)
    }
  }, [])

  return(
    <Modal show={show} centered={true} onHide={closeModal}>
      <a href="https://www.hanken.fi/sv/sok-till-hanken" target='_blank' rel='noreferrer'>
        <img src={HankenAdvertisement} alt='Hae opiskelemaan Hankeniin' />
      </a>
    </Modal>
  )
}

export default Advertisements
