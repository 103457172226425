import axios from 'axios'
import { BASE_URL, ERROR_VOTES_URL } from '../constants/constants'


const getErrorVotes = async () => {
  const response = await axios.get(BASE_URL + ERROR_VOTES_URL)
  return response.data
}

const updateErrorVote = async (id, newVotes) => {
  const response = await axios.patch(BASE_URL + ERROR_VOTES_URL + id + '/', { votes: newVotes })
  return response.data
}

export default {
  getErrorVotes,
  updateErrorVote
}
