import React, { useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import {
  LOGOS_AVAILABLE,
  MOBILE_BREAKPOINT, YEARS_AMK, YEARS_UNIVERSITY
} from '../../../../constants/constants'
import { useLocation } from 'react-router-dom'
import CardContent from './CardContent'
import styles from './ProgramCard.module.css'
import MobileCard from './MobileCard'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { createSubjectcardClickEvent } from '../../../../utils/analytics'
import { useTranslation } from 'react-i18next'

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <div className={styles.showMore}>
      <Button
        className="button-secondary"
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    </div>
  )
}

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired
}

const ProgramCard = ({ program }) => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const [mobile, setWindowSize] = useState(getWindowSize())
  const [mobileOpen, setMobileOpen] = useState(false)
  const [expanded, setExpand] = useState(false)

  const location = useLocation()

  const results = useSelector(state => {
    if(location.pathname === '/yliopistoon'){
      return state.universities.results[program.id - 1]
    } else {
      return state.amks.results[program.id - 1]
    }
  })


  const years = location.pathname === '/yliopistoon' ? YEARS_UNIVERSITY : YEARS_AMK

  const themeChooser = year => {
    if (!program.diplom) {
      return styles.noDiplom
    }
    if (!results) {
      return styles.diplomNotSet
    }
    if (location.pathname === '/ammattikorkeaan' && !program.minimumPointsMatriculation[0][year]) {
      return styles.diplomNotSet
    }
    if (location.pathname === '/yliopsitoon' && !program.minimumPoints[0][year]) {
      return styles.diplomNotSet
    }
    if (results[year].gotIn) {
      return styles.gotIn
    } else return styles.notIn
  }

  const iconChooser = year => {
    if (!program.diplom) {
      return 'lock-fill'
    }
    if (!results) {
      return 'question-circle-fill'
    }
    if (location.pathname === '/ammattikorkeaan' && !program.minimumPointsMatriculation[0][year]) {
      return 'question-circle-fill'
    }
    if (location.pathname === '/yliopsitoon' && !program.minimumPoints[0][year]) {
      return 'question-circle-fill'
    }
    if (results[year].gotIn) {
      return 'check-circle-fill'
    } else return 'x-circle-fill'
  }

  const handleOffcanvasOpening = () => {
    if (mobile) {
      setMobileOpen(true)
      createSubjectcardClickEvent(program.id, results && results[`year_${years[0]}`].gotIn, expanded)
    }
  }

  const handleClose = () => {
    setMobileOpen(false)
  }

  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  const handleExpand = () => {
    setExpand(!expanded)
    createSubjectcardClickEvent(program.id, results && results[`year_${years[0]}`].gotIn, expanded)
  }

  useEffect(() => {
    
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return(
    <div className={styles.card} key={program.id}>
      <Accordion defaultActiveKey="0">
        <div onClick={handleOffcanvasOpening}>
          <div className={styles.cardRow}>
            {LOGOS_AVAILABLE &&
              <div className={styles.imgCol}>
                <i className={`bi bi-file-earmark-image-fill ${styles.imageAlt}`}></i>
              </div>
            }
            <div className={styles.contentCol}>
              <h2 className="mb-1">
                {program.name}
              </h2>
              <p>{program.school.name}</p>
              <div className={styles.location}>
                <i className="bi bi-geo-alt me-1"></i>
                <p className="mb-0">{program.location.name}</p>
              </div>
              {!mobile &&
                <div onClick={handleExpand} className={styles.toggleWrapper}>
                  <CustomToggle eventKey={program.name}>
                    <i className={`bi bi-caret-${expanded ? 'up' : 'down'}-fill text-primary me-2`}></i>
                    {expanded ? t('showLess') : t('showMore')}
                  </CustomToggle>
                </div>
              }
            </div>
            <div className={styles.iconCol}>
              {years.slice(0, 2).map(year => (
                <div className={`${styles.iconRow} ${themeChooser(`year_${year}`)}`} key={year}>
                  <i className={`bi bi-${iconChooser(`year_${year}`)} ${styles.headerIcon}`}></i>
                  <p className="mb-0">{year}</p>
                </div>
              ))
              }
            </div>
          </div>
          {!mobile &&
            <Accordion.Collapse eventKey={program.name}>
              <CardContent program={program} />
            </Accordion.Collapse>
          }
        </div>
      </Accordion>
      <MobileCard
        open={mobileOpen}
        program={program}
        handleClose={handleClose}
        iconChooser={iconChooser}
        themeChooser={themeChooser}
      />
    </div>
  )
}

ProgramCard.propTypes = {
  program: PropTypes.object.isRequired
}

export default ProgramCard
