/* eslint-disable no-undef */

// How long data is kept in local storage
export const ONE_DAY_EXPIRY = 86400000 // 24 hours in milliseconds
export const ONE_WEEK_EXPIRY = 604800000 // 7 days in milliseconds

// Day to count to for HomePageCounter
// FROM https://currentmillis.com/
export const DAY_FOR_COUNTING = 1680181200000//15.3.2023 15:00 as current time (since 01.01.1970)
// export const NEXT_DAY_FOR_COUNTING = 1674046800000//15.03.2024 15.00 as current time (since 01.01.1970)

export const OBJECTS_ON_ONE_PAGE = 25

export const ONE_DAY = 86400000
export const ONE_HOUR = 3600000
export const ONE_MINUTE = 60000
export const SEVEN_SECONDS = 7000
export const FIVE_SECONDS = 5000
export const HALF_SECOND = 500
export const QUARTER_SECONDS = 250

export const THOUSAND = 1000
export const HUNDRED = 100
export const TEN = 10

export const MINIMUM_AMOUNT_OF_SUBJECTS = 4

export const IMAGE_BASE_URL = 'https://korkeakouluun-images.s3.eu-central-1.amazonaws.com'
export const BASE_URL = process.env.REACT_APP_BACKEND_URL
export const UNIVERSITIES_URL = '/universities/'
export const HIGHSCHOOLS_URL = '/highschools/'
export const AMKS_URL = '/schools-of-applied-science/'
export const LOCATIONS_URL = '/locations/'
export const ERROR_VOTES_URL = '/error-votes/'

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

export const YEARS_UNIVERSITY = [
  '2022',
  '2021',
  '2020'
]
export const YEARS_AMK = ['2022', '2021']
export const YEARS_HIGHSCHOOL = [
  '2022',
  '2021',
  '2020',
  '2019',
  '2018'
]

export const MOBILE_BREAKPOINT = 769
export const NAV_BREAKPOINT = 1201
export const HIDE_BREAKPOINT = 666

export const SEARCH_WEIGHT_LOCATION = 0.85
export const SEARCH_WEIGHT_SCHOOL = 0.85
export const SEARCH_WEIGHT_NAME = 0.65

export const ALLOWED_LETTERS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  ','
]

export const LOGOS_AVAILABLE = false

// TOGGLE ON WHEN NEW ADS ARE SOLD
export const ADS_AVAILABLE = false

export const LOCALES_AVAILABLE = false
