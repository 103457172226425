import gtag from 'ga-gtag'

export const createSubjectcardClickEvent = (cardId = '', gotIn = '', wasExpanded = '') => {
  gtag('event', 'Ainekortti_painaminen', {
    'Hakukohde_nimi': cardId,
    'Got_In': gotIn,
    'Was_expanded': wasExpanded
  })
}

export const createSearchEvent = (searchTerm = '') => {
  gtag('event', 'Painaa hakunappia', {
    'Hakusana': searchTerm
  })
}
