import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { YEARS_AMK } from '../constants/constants'
import AMKService from '../services/AMKs'
import { filter } from '../utils/filter'
import { alphabeticalCompare } from '../utils/sort'

const latestYear = YEARS_AMK[0]

const initialState = {
  programs: [],
  results: [],
  calculateStatus: 'idle',
  fetchStatus: 'idle',
  error: null
}

export const calculateAMKResults = createAsyncThunk('AMK/calculateAMKResults', async subjects => {
  const response = await AMKService.calculateResults(subjects)
  return response
})

export const fetchAMKs = createAsyncThunk('AMK/fetchAMKs', async () => {
  const response = await AMKService.getAMKPrograms()
  return response.map(program => ({
    ...program,
    show: true
  }))
})

const programSort = (programs, sortType, results) => {

  const highestMarginCompare = (a, b) => {
    const x = results[a.id - 1][`year_${latestYear}`].margin
    const y = results[b.id - 1][`year_${latestYear}`].margin
    if(x === null && y === null) {
      return 0
    }
    if(x === null) {
      return 1
    }
    if(y === null) {
      return -1
    }
    if (x < y) { return 1 }
    if (x > y) { return -1 }
    return 0
  }


  if(sortType === 'HIGHEST_MARGIN'){
    return programs.sort(highestMarginCompare)
  }else{
    return programs.sort(alphabeticalCompare)
  }
}


const AMKSlice = createSlice({
  name: 'AMK',
  initialState,
  reducers: {
    resetAMKFilters: state => {
      state.programs = state.programs.map(program => ({
        ...program,
        show: true
      }))
    },
    setAMKFilters: (state, action) => {
      state.programs = filter(state.programs, state.results, action.payload)
    },
    setAMKSort: (state, action) => {
      if(state.results.length !== 0) {
        state.programs = programSort(state.programs, action.payload, state.results)
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(calculateAMKResults.pending, state  => {
        state.calculateStatus = 'loading'
      })
      .addCase(calculateAMKResults.fulfilled, (state, action) => {
        state.results = action.payload
        state.calculateStatus = 'success'
      })
      .addCase(calculateAMKResults.rejected, (state, action) => {
        state.error = action.error
        state.calculateStatus = 'error'
      })
      .addCase(fetchAMKs.pending, state => {
        state.fetchStatus = 'loading'
      })
      .addCase(fetchAMKs.fulfilled, (state, action) => {
        state.programs = programSort(action.payload, 'ALPHABETICAL', state.results)
        state.fetchStatus = 'success'
      })
      .addCase(fetchAMKs.rejected, (state, action) => {
        state.error = action.error
        state.fetchStatus = 'error'
      })
  }
})


export default AMKSlice.reducer
export const {
  resetAMKFilters, setAMKFilters, setAMKSort
} = AMKSlice.actions
