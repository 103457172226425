import React from 'react'
import { NAV_BREAKPOINT } from '../../../../constants/constants'
import styles from './GradeCard.module.css'
import PropTypes from 'prop-types'

const GradeCard = props => (
  <div className={styles.element}>
    <div className={styles.subject}>
      <h2 className={props.width > NAV_BREAKPOINT ? 'mb-0' : 'mb-3'}>{props.item.diplomaName}</h2>
    </div>
    {props.width > NAV_BREAKPOINT
      ?
      <div className={styles.grade}>
        <div className={props.item.grade === 'L' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'L')}>L</div>
        <div className={props.item.grade === 'E' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'E')}>E</div>
        <div className={props.item.grade === 'M' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'M')}>M</div>
        <div className={props.item.grade === 'C' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'C')}>C</div>
        <div className={props.item.grade === 'B' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'B')}>B</div>
        <div className={props.item.grade === 'A' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'A')}>A</div>
        <div className={props.item.grade === 'I' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'I')}>I</div>
      </div>
      :
      <div className={styles.grade}>
        <div className={styles.mobileRow}>
          <div className={props.item.grade === 'L' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'L')}>L</div>
          <div className={props.item.grade === 'E' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'E')}>E</div>
          <div className={props.item.grade === 'M' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'M')}>M</div>
        </div>
        <div className={styles.mobileRow}>
          <div className={props.item.grade === 'C' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'C')}>C</div>
          <div className={props.item.grade === 'B' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'B')}>B</div>
          <div className={props.item.grade === 'A' ? styles.activeItem : styles.gradeItem} onClick={() => props.setGrade(props.item, 'A')}>A</div>
        </div>
      </div>
    }
    <div className={styles.delete}>
      <i className={`bi bi-trash3-fill ${styles.deleteIcon}`} onClick={() => props.handleDelete(props.item)}></i>
    </div>
  </div>
)

GradeCard.propTypes = {
  item: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  setGrade: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export default GradeCard
