import React, { useEffect, useState } from 'react'
import styles from './Loading.module.css'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Loading = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [show, setShow] = useState(false)

  const body = document.getElementById('body')

  if (show) {
    body.style.overflow = 'hidden'
  }

  const destroyTime = 1400

  const status = useSelector(state => {
    switch(location.pathname) {
    case '/yliopistoon':
      return (state.universities.calculateStatus === 'loading' || state.universities.fetchStatus === 'loading') ? 'loading' : 'ready'
    case '/ammattikorkeaan':
      return (state.amks.calculateStatus === 'loading' || state.amks.fetchStatus === 'loading') ? 'loading' : 'ready'
    case '/lukioon':
      return state.highSchools.status === 'loading' ? 'loading' : 'ready'
    default:
      return 'ready'
    }
  })
  

  useEffect(() => {
    if (status === 'loading') {
      setShow(true)
    } else if (show === true) {
      handleDisappear()
    }
  }, [status])

  const handleDisappear = () => {
    const wrapperElem = document.getElementById('loading-wrapper')
    const spinner = document.getElementById('spinner')
    const heading = document.getElementById('headingRow')
    const paragraph = document.getElementById('paragraphRow')

    spinner.style.transform = 'translate(-100px)'
    spinner.style.opacity = 0
    spinner.style.transition = 'all cubic-bezier(.87,.17,.82,.36) 0.4s'

    heading.style.transform = 'translate(-100px)'
    heading.style.opacity = 0
    heading.style.transition = 'all cubic-bezier(.87,.17,.82,.36) 0.4s 0.2s'

    paragraph.style.transform = 'translate(-100px)'
    paragraph.style.opacity = 0
    paragraph.style.transition = 'all cubic-bezier(.87,.17,.82,.36) 0.4s 0.4s'

    wrapperElem.style.opacity = 0
    wrapperElem.style.transition = 'all ease-in 0.2s 1s'

    window.scrollTo(0, 0)

    setTimeout(() => {
      body.style.overflowY = 'scroll'
      setShow(false)
    }, [destroyTime])
    
  }


  return(
    <>
      {show &&
        <div className={styles.wrapper} id="loading-wrapper">
          <div className={styles.animationRow}>
            <div className={styles.dualRing} id='spinner'></div>
          </div>
          <div className={styles.animationRow}>
            <h1 className="mb-3" id='headingRow'>{t('loading.title')}</h1>
            <p id='paragraphRow'>{t('loading.description')}</p>
          </div>
        </div>
      }
    </>
  )
}


export default Loading
