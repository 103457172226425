import { configureStore } from '@reduxjs/toolkit'
import diplomReducer from '../reducers/diplomReducer'
import universitiesReducer from '../reducers/universitiesReducer'
import AMKReducer from '../reducers/AMKReducer'
import highSchoolReducer from '../reducers/highSchoolReducer'


const store = configureStore({
  reducer: {
    diplom: diplomReducer,
    universities: universitiesReducer,
    amks: AMKReducer,
    highSchools: highSchoolReducer
  }
})

export default store
