import React from 'react'
import styles from './MinimumRequirement.module.css'
import propTypes from 'prop-types'

const MinimumRequirement = props => {
  const { program, year } = props
  const requirements = program.minimumRequirements[0][`year_${year}`]
  const exists = requirements !== null
  const parsed = exists ? requirements.replace(/[\\(\\)]/g, '').replace(/\|/g, 'tai')
    .split('&') : []
  
  return (
    <>
      {exists
        ?
        <div className={styles.tableTextNormal}>
          {parsed.length > 1
            ?
            <ul className='mb-0'>
              {parsed.map(requirement => <li key={requirement} className='mb-1'>{requirement}</li>)}
            </ul>
            : <p>{parsed[0]}</p>
          }
        </div>
        :
        <div className={`${styles.unknown}`}>
          <i className={`bi bi-info-circle-fill ${styles.headerIcon}`}></i>
          <p className="mb-0">Ei kynnysehtoja</p>
        </div>
      }
    </>
  )
}

MinimumRequirement.propTypes = {
  program: propTypes.object.isRequired,
  year: propTypes.string.isRequired
}

export default MinimumRequirement
