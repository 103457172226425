import React, { useState, useEffect } from 'react'
import Progressbar from './Progressbar'
import { useSelector } from 'react-redux'
import {
  MOBILE_BREAKPOINT, YEARS_AMK, YEARS_UNIVERSITY
} from '../../../../constants/constants'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const InfoDashboard = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const latestYear = location.pathname === '/yliopistoon' ? YEARS_UNIVERSITY[0] : YEARS_AMK[0]

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }
  
  const [mobile, setWindowSize] = useState(getWindowSize())

  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // const gotIn = results.filter(program => {
  //   if(!program.diplom){
  //     return false
  //   }
  //   if(program.results === null){
  //     return false
  //   }
  //   return program.results['year_2021'].gotIn
  // }).length

  const gotIn = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.programs.filter(program => {
        if(!program.diplom){
          return false
        }
        if(state.universities.results.length === 0){
          return false
        }
        return state.universities.results[program.id - 1][`year_${latestYear}`].gotIn
      }).length
    case '/ammattikorkeaan':
      return state.amks.programs.filter(program => {
        if(!program.diplom){
          return false
        }
        if(state.amks.results.length === 0){
          return false
        }
        return state.amks.results[program.id - 1][`year_${latestYear}`].gotIn
      }).length
    case '/lukioon':
      return state.highSchools.programs.filter(program => {
        if(program.special){
          return false
        }
        return state.diplom.gpa >= program.minimumPoints[0][`year_${latestYear}`]
      }).length
    default: return 0
    }
  })

  const total = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.programs.filter(program => program.diplom).length
    case '/ammattikorkeaan':
      return state.amks.programs.filter(program => program.diplom).length
    case '/lukioon':
      return state.highSchools.programs.filter(program => !program.special).length
    default: return 0
    }
  })

  const showProgressbar = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.results.length !== 0
    case '/ammattikorkeaan':
      return state.amks.results.length !== 0
    case '/lukioon':
      return state.diplom.gpa !== null && state.highSchools.programs.length !== 0
    default: return false
    }
  })

  return(
    <>
      {(!mobile || showProgressbar) && <Progressbar title={t('pageBuilder.header.results')} gotIn={gotIn} total={total} show={showProgressbar} />}
    </>
  )
}


export default InfoDashboard
