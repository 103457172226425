import React from 'react'
import PropTypes from 'prop-types'
import styles from './Switcher.module.css'
import { Offcanvas, Button } from 'react-bootstrap'

const MobileSwitcher = props => (
  <Offcanvas show={props.open} onHide={props.handleClose} placement='bottom' className={styles.element}>
    <Offcanvas.Header className={styles.header}>
      <Offcanvas.Title className={styles.title}>Lajittele</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className={styles.body}>
      <div onClick={() => props.handleClick('ALPHABETICAL')} className={`${styles.dropdownRow} ${props.sort === 'ALPHABETICAL' && styles.chosen}`}>
        <div className={styles.flexFirst}>
          <i className={`bi bi-sort-alpha-down ${styles.icon}`}></i>
          <p className="mb-0">Aakkosjärjestyksen mukaan</p>
        </div>
        <div className={styles.flexSecond}>
          {props.sort === 'ALPHABETICAL' && <i className={`bi bi-check-lg ${styles.checkIcon}`}></i>}
        </div>
      </div>
      <div onClick={() => props.marginAvailable && props.handleClick('HIGHEST_MARGIN')} className={`${styles.dropdownRow} ${props.sort === 'HIGHEST_MARGIN' && styles.chosen} ${!props.marginAvailable && styles.disabled}`}>
        <div className={styles.flexFirst}>
          <i className={`bi bi-percent ${styles.icon}`}></i>
          <p className="mb-0">Marginaalin mukaan</p>
        </div>
        <div className={styles.flexSecond}>
          {props.sort === 'HIGHEST_MARGIN' && <i className={`bi bi-check-lg ${styles.checkIcon}`}></i>}
        </div>
      </div>
      <div className={styles.close}>
        <Button className="button-secondary" onClick={props.handleClose}>Sulje</Button>
      </div>
    </Offcanvas.Body>
  </Offcanvas>
)

MobileSwitcher.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  marginAvailable: PropTypes.bool.isRequired
}

export default MobileSwitcher
