import React from 'react'
import styles from './Notification.module.css'
import PropTypes from 'prop-types'


const Notification = props => (
  <div className={styles.notificationWrapper}>
    {props.text.map((item, index) => (
      <div className={styles.notification} key={index} id="notification">
        <div className={styles.icon}>
          <i className="bi bi-exclamation-diamond-fill"></i>
        </div>
        <div className={styles.textColumn}>
          <div className={styles.header}>
            <h1>{props.title}</h1>
          </div>
          <div className={styles.text}>
            <p className="mb-0">{item}</p>
          </div>
        </div>
        <div className={styles.closeIcon}>
          <i className="bi bi-x" onClick={props.close}></i>
        </div>
      </div>
    ))}
  </div>
  
)

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
}

export default Notification
