import axios from 'axios'
import { BASE_URL, UNIVERSITIES_URL } from '../constants/constants'


const getUniversityPrograms = async () => {
  const response = await axios.get(BASE_URL + UNIVERSITIES_URL + 'programs/')
  return response.data
}

const calculateResults = async subjects => {
  const response = await axios.post(BASE_URL + UNIVERSITIES_URL + 'calculate/', subjects)
  return response.data
}

export default {
  getUniversityPrograms,
  calculateResults
}
