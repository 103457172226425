import React, { useEffect, useState } from 'react'
import {
  Row, Col, Container
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import korkeakouluun from '../../static/images/korkeakouluun_logo_footer.png'
import { MOBILE_BREAKPOINT } from '../../constants/constants'
import { useTranslation } from 'react-i18next'


const Footer = () => {
  const { t } = useTranslation()
  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return(
    <div className={`w-100 ${styles.footerWrapper}`}>
      <Container>
        <Row>
          <Col md={4} className={` ${styles.column}`}>
            <h2 className="mb-4">{t('navigation.title')}</h2>
            <Link to="/" className={styles.footerLink}>{t('footer.navigation.home')}</Link>
            <Link to="/yliopistoon" className={styles.footerLink}>{t('navigation.university')}</Link>
            <Link to="/ammattikorkeaan" className={styles.footerLink}>{t('navigation.amk')}</Link>
            <Link to="/lukioon" className={styles.footerLink}>{t('navigation.highschool')}</Link>
            <a href="https://www.trinode.fi" className={styles.footerLink}>{t('navigation.about')}</a>
          </Col>
          <Col md={4} className={`text-center ${windowSize < MOBILE_BREAKPOINT && 'order-first'}`}>
            <img src={korkeakouluun} className={styles.img} />
            <div className={styles.motto}>
              <p>{t('footer.slogan')}</p>
            </div>
            <div className="w-100 text-center">
              <a href="https://www.instagram.com/korkeakouluun">
                <i className={`bi bi-instagram icon-style ${styles.icon} ${windowSize < MOBILE_BREAKPOINT ? 'me-3' : 'me-4'}`}></i>
              </a>
              <a href="https://www.linkedin.com/company/79335709">
                <i className={`bi bi-linkedin icon-style ${styles.icon} ${windowSize < MOBILE_BREAKPOINT ? 'ms-3' : 'ms-4'}`}></i>
              </a>
            </div>
          </Col>
          <Col md={4} className={styles.column}>
            <h2 className="mb-4">{t('footer.contact')}</h2>
            <p className={styles.name}>Frans Kangasniemi</p>
            <a href="mailto: frans.kangasniemi@trinode.fi" className={styles.footerLink}>frans.kangasniemi@trinode.fi</a>
          </Col>
        </Row>
        <Row className={`text-center ${styles.copyright}`}>
          <p>
            © Copyright - Trinode Software Oy
          </p>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
