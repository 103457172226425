import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import styles from './Sort.module.css'
import { MOBILE_BREAKPOINT } from '../../../constants/constants'
import MobileSwitcher from './MobileSwitcher'
import { setUniversitySort } from '../../../reducers/universitiesReducer'
import { setAMKSort } from '../../../reducers/AMKReducer'
import { setHighSchoolSort } from '../../../reducers/highSchoolReducer'
import { useTranslation } from 'react-i18next'

const ResultsAmountAndSort = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.resultsAmountAndSort'})
  const dispatch = useDispatch()
  const location = useLocation()

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const [mobile, setWindowSize] = useState(getWindowSize())

  const [open, setOpen] = useState(false)
  const [sort, setSort] = useState('ALPHABETICAL')

  const marginAvailable = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.results.length !== 0
    case '/ammattikorkeaan':
      return state.amks.results.length !== 0
    case '/lukioon':
      return state.diplom.gpa !== null
    default:
      return false
    }
  })
    
  const handleShow = () => setOpen(!open)

  const handleClose = () => setOpen(false)

  const handleClick = value => {
    switch(location.pathname) {
    case '/yliopistoon':
      dispatch(setUniversitySort(value))
      break
    case '/ammattikorkeaan':
      dispatch(setAMKSort(value))
      break
    case '/lukioon':
      dispatch(setHighSchoolSort(value))
      break
    default:
      break
    }

    setOpen(false)
    setSort(value)
  }

  useEffect(() => {
    handleClick('ALPHABETICAL')
  }, [location.pathname])
  

  const itemsToShow = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.programs
    case '/ammattikorkeaan':
      return state.amks.programs
    case '/lukioon':
      return state.highSchools.programs
    default:
      return state.universities.programs
    }
  }).filter(program => program.show).length

    
  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])


  return (
    <div className={`w-100 ${styles.sortWrapper}`}>
      <Container>
        <div className="w-100 d-flex justify-content-between">
          <h5 className="text-secondary pt-2 d-flex">{itemsToShow} {t('count')}</h5>
          <div className={styles.element}>
            <div onClick={handleShow} className={styles.chooser}>
              <i className={`bi bi-sort-down ${styles.icon}`}></i>
              {!mobile &&
                <>
                  <p className="mb-0">{t('sort')}</p>
                  <i className={`bi bi-chevron-${open ? 'up' : 'down'} ${styles.chevronIcon}`}></i>
                </>
              }
            </div>
            {mobile && <MobileSwitcher sort={sort} handleClick={handleClick} handleClose={handleClose} open={open} marginAvailable={marginAvailable} />}
            {!mobile && open &&
              <div className={styles.dropdown}>
                <div onClick={() => handleClick('ALPHABETICAL')} className={`${styles.dropdownRow} ${sort === 'ALPHABETICAL' && styles.chosen}`}>
                  <i className={`bi bi-sort-alpha-down ${styles.icon}`}></i>
                  <p className="mb-0">{t('alphabetical')}</p>
                  {sort === 'ALPHABETICAL' && <i className={`bi bi-check-lg ${styles.checkIcon}`}></i>}
                </div>
                <div onClick={() => marginAvailable && handleClick('HIGHEST_MARGIN')} className={`${styles.dropdownRow} ${sort === 'HIGHEST_MARGIN' && styles.chosen} ${!marginAvailable && styles.disabled}`}>
                  <i className={`bi bi-percent ${styles.icon}`}></i>
                  <p className="mb-0">{t('margin')}</p>
                  {sort === 'HIGHEST_MARGIN' && <i className={`bi bi-check-lg ${styles.checkIconShorter}`}></i>}
                </div>
              </div>
            }
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ResultsAmountAndSort
