import React from 'react'
import { Row } from 'react-bootstrap'
import styles from './SpecialHighschool.module.css'
import { useTranslation } from 'react-i18next'

const SpecialHighschool = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing.highschool.special'})
  return (
    <Row>
      <div className={styles.element}>
        <i className="bi bi-brush-fill me-2"></i>
        <p className={styles.info}>{t('title')}</p>
      </div>
      <p className="px-0">{t('description')}</p>
    </Row>
  )
}

export default SpecialHighschool
