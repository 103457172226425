import React from 'react'
import {
  Row, Offcanvas, Button
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './MobileCard.module.css'
import MobileContent from './MobileContent'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MobileCard = props => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  return (
    <Offcanvas show={props.open} onHide={props.handleClose} placement='bottom' className={styles.element}>
      <Offcanvas.Header className={styles.header}>
        <Offcanvas.Title className={styles.title}>{t('mobileCardTitle')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.body}>
        <div className={styles.bodyContent}>
          <MobileContent program={props.program}/>
          <Row>
            <Button as={Link} to="/feedback" className="button-secondary">{t('opintopolkuun.cta2')}</Button>
          </Row>
        </div>
        { props.program.url && <div className={styles.transparent}></div> }
        {props.program.url &&
          <Row className={styles.controlRow}>
            <Button target='_blank' rel="noreferral noopener" className="button-primary" href={props.program.url}>{t('opintopolkuun.cta1')}</Button>
          </Row>
        }
        <div className={styles.close}>
          <Button className="button-secondary" onClick={props.handleClose}>{t('close')}</Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

MobileCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired
}

export default MobileCard
