import universityService from '../services/universities'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { filter } from '../utils/filter'
import { YEARS_UNIVERSITY } from '../constants/constants'
import { alphabeticalCompare } from '../utils/sort'

const latestYear = YEARS_UNIVERSITY[0]

const initialState = {
  programs: [],
  results: [],
  calculateStatus: 'idle',
  fetchStatus: 'idle',
  error: null
}

export const calculateUniversities = createAsyncThunk('universities/calculateUniversities', async subjects => {
  const response = await universityService.calculateResults(subjects)
  return response
})

export const fetchUniversities = createAsyncThunk('universities/fetchUniversities', async () => {
  const response = await universityService.getUniversityPrograms()
  return response.map(program => ({
    ...program,
    show: true
  }))
})


const programSort = (programs, sortType, results) => {

  const highestMarginCompare = (a, b) => {
    const x = results[a.id - 1][`year_${latestYear}`].margin
    const y = results[b.id - 1][`year_${latestYear}`].margin
    if(x === null && y === null) {
      return 0
    }
    if(x === null) {
      return 1
    }
    if(y === null) {
      return -1
    }
    if (x < y) { return 1 }
    if (x > y) { return -1 }
    return 0
  }


  if(sortType === 'HIGHEST_MARGIN'){
    return programs.sort(highestMarginCompare)
  }else{
    return programs.sort(alphabeticalCompare)
  }
}

const universitiesSlice = createSlice({
  name: 'universities',
  initialState,
  reducers: {
    resetUniversityFilters: state => {
      state.programs = state.programs.map(program => ({
        ...program,
        show: true
      }))
    },
    setUniversityFilters: (state, action) => {
      state.programs = filter(state.programs, state.results, action.payload)
    },
    setUniversitySort: (state, action) => {
      if(state.results.length !== 0) {
        state.programs = programSort(state.programs, action.payload, state.results)
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(calculateUniversities.pending, state => {
        state.calculateStatus = 'loading'
      })
      .addCase(calculateUniversities.fulfilled, (state, action) => {
        state.results = action.payload
        state.calculateStatus = 'success'
      })
      .addCase(calculateUniversities.rejected, (state, action) => {
        state.error = action.error.message
        state.calculateStatus = 'error'
      })
      .addCase(fetchUniversities.pending, state => {
        state.fetchStatus = 'loading'
      })
      .addCase(fetchUniversities.fulfilled, (state, action) => {
        state.programs = programSort(action.payload, 'ALPHABETICAL', state.results)
        state.fetchStatus = 'success'
      })
      .addCase(fetchUniversities.rejected, (state, action) => {
        state.error = action.error.message
        state.fetchStatus = 'error'
      })
  }
})


export default universitiesSlice.reducer
export const {
  resetUniversityFilters, setUniversityFilters, setUniversitySort
} = universitiesSlice.actions
