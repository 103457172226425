import React, { useState, useEffect } from 'react'
import { ONE_WEEK_EXPIRY } from '../../constants/constants'
import { Button, Container } from 'react-bootstrap'
import { setItemWithExpiryTime, getItemWithExpiry } from '../../utils/localStorageExpiry'
import styles from './Cookies.module.css'
import { useTranslation } from 'react-i18next'

const CookieNotification = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const acceptedCookies = getItemWithExpiry('acceptedCookies')
    if (!acceptedCookies || acceptedCookies !== 'true') {
      setShow(true)
    }

  }, [])

  const handleCloseToast = () => {
    setShow(false)
    // Set to ask for cookies in a week
    setItemWithExpiryTime('acceptedCookies', 'true', ONE_WEEK_EXPIRY)
  }
  return(
    <>
      {show &&
        <div className={styles.cookies}>
          <Container>
            <div className={styles.wrapper}>
              <div className={styles.textColumn}>
                <p className="mb-0">
                  {t('cookies.description')}
                  <span><a href='/documents/PrivacyNotice.pdf' target="_blank" className={styles.linkStyle}>{t('cookies.learnMore')}</a></span>
                </p>
              </div>
              <div className={styles.buttonColumn}>
                <Button onClick={handleCloseToast} className="button-primary">{t('cookies.accept')}</Button>
              </div>
            </div>
          </Container>
        </div>
      }
    </>
  )
}

export default CookieNotification
