import React, { useState, useEffect } from 'react'
import {
  Container, Row, Button
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import styles from './404.module.css'
import errorService from '../../services/errorVotes'
import { IMAGE_BASE_URL } from '../../constants/constants'
import DeveloperCard from './DeveloperCard'
import { useTranslation } from 'react-i18next'

const Error404 = () => {
  const { t } = useTranslation()
  const [votes, setVotes] = useState([])
  const fetchVotes = async () => {
    
    const newVotes = await errorService.getErrorVotes()
    setVotes(newVotes)
  }

  const navigate = useNavigate()

  useEffect(() => {
    fetchVotes()
  }, [])

  const handleAdd = (id, newVotes) => {
    errorService.updateErrorVote(id, newVotes)
    const newVotesObject = votes.map(item => {
      if (item.id === id) {
        item.votes = newVotes
        return item
      } else return item
    })
    setVotes(newVotesObject)
    navigate('/')
  }

  return (
    <Container className={styles.wrapper}>
      <Row className={styles.preHeader}>
        <p className="text-primary mb-0">{t('errorPage.slogan')}</p>
      </Row>
      <Row className={styles.header}>
        <h1>404</h1>
      </Row>
      <Row className="mx-0">
        <p className="text-secondary">{t('errorPage.description')}</p>
      </Row>
      <Row className="justify-content-center mx-0">
        <Button as={Link} to='/'className="outline-primary">{t('errorPage.cta')}</Button>
      </Row>
      <Row className={styles.guiltyHeader}>
        <h2>{t('errorPage.voting.title')}</h2>
      </Row>
      <Row className="mx-0">
        <p className="text-secondary">{t('errorPage.voting.description')}</p>
      </Row>
      <div className={styles.imgRow}>
        {votes.map(item => (<DeveloperCard key={item.id} id={item.id} name={item.name} votes={item.votes} handleAdd={handleAdd} url={`${IMAGE_BASE_URL}/profileImages/${item.id}.jpg`} />))}
      </div>
    </Container>
  )
}

export default Error404
