import React from 'react'
import {
  Row, Offcanvas, Button
} from 'react-bootstrap'
import CustomCheckbox from '../CustomCheckbox'
import styles from './MobileSelector.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MobileSelector = props => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.filters.dropdown'})
  return (
    <Offcanvas show={props.open} onHide={props.handleClose} placement='bottom' className={styles.element}>
      <Offcanvas.Header className={styles.header}>
        <Offcanvas.Title className={styles.title}>{`${t('select')} ${props.title === 'Ammattikorkeakoulu' ? 'AMK' : props.title.toLowerCase()}`}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.body}>
        <div className={styles.bodyContent}>
          <div className={styles.contentWrapper}>
            <div className={styles.searchContainer}>
              <i className={`bi bi-search ${styles.searchColor}`}></i>
              <input
                type='text'
                placeholder={props.placeholder}
                value={props.textInput}
                onChange={event => props.handleTextField(event)}
                className={styles.search}
              />
              {
                props.closeButton &&
                  <i className={`bi bi-x-circle-fill ${styles.closeButton}`} onClick={props.handleClearText}></i>
              }
            </div>
          </div>
          <div>
            {props.options.filter(item => item.toLowerCase().includes(props.textInput.toLowerCase())).map(item => (
              <div className={styles.contentElement} key={item}>
                <CustomCheckbox checked={props.choises !== {} ? props.choises[item] : false} name={item} label={item} handleClick={props.handleCheckboxClick} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.transparent}></div>
        <Row className={styles.controlRow}>
          <Button className="button-primary" onClick={props.handleSave}>{t('save')}</Button>
        </Row>
        <div className={styles.close}>
          <Button className="button-secondary" onClick={props.handleClose}>{t('close')}</Button>
        </div>
        <div className={styles.reset}>
          <Button className="button-secondary" onClick={props.handleReset}>{t('clear')}</Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
  

MobileSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  textInput: PropTypes.string.isRequired,
  closeButton: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleTextField: PropTypes.func.isRequired,
  handleClearText: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  choises: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
}

export default MobileSelector
