import React from 'react'
import styles from './NoResults.module.css'
import { useTranslation } from 'react-i18next'

const NoResults = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing.noResults'})
  return (
    <div className={styles.element}>
      <i className="bi bi-emoji-frown me-2"></i>
      <p className="mb-0">{t('description')}</p>
    </div>
  )
}

export default NoResults
