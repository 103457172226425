import React from 'react'
import styles from './Subject.module.css'
import PropTypes from 'prop-types'

const Subject = props => (
  <div onClick={() => props.handleClick(props.diplomaName)} className={props.isChosen ? styles.chosen : styles.default}>
    <div>{props.name}</div>
  </div>
)

Subject.propTypes = {
  name: PropTypes.string.isRequired,
  isChosen: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  diplomaName: PropTypes.string.isRequired
}

export default Subject
