import {
  YEARS_UNIVERSITY, SEARCH_WEIGHT_LOCATION, SEARCH_WEIGHT_NAME, SEARCH_WEIGHT_SCHOOL, YEARS_HIGHSCHOOL
} from '../constants/constants'
// eslint-disable-next-line no-undef
const distance = require('jaro-winkler')


const standardize = input => {
  const problems = '[,.-;:_]'
  let period = input
  // return input.toLowerCase().replaceAll("/[,.-;:_]/g"," ").replaceAll("  "," ")
  for (let r = 0; r < problems.length; r++) {
    period = period.split(problems[r]).join('')
  }
  return period.toLowerCase()
}


export const filter = (programs, results, data) => programs.map(program => {
  const newProgram = {
    ...program,
    show: true
  }
  if(data.diplom && !program.diplom){
    newProgram.show = false
    return newProgram
  }

  if(data.gotIn && results !== [] && !results[program.id - 1][`year_${YEARS_UNIVERSITY[0]}`].gotIn){
    newProgram.show = false
    return newProgram
  }

  if(data.location.length !== 0 && !data.location.includes(program.location.name)){
    newProgram.show = false
    return newProgram
  }

  if(data.school.length !== 0 && !data.school.includes(program.school.name)){
    newProgram.show = false
    return newProgram
  }

  if(data.text !== ''){
    const standardized = standardize(data.text)
    if(
      distance(standardize(program.name), standardized) > SEARCH_WEIGHT_NAME ||
        distance(standardize(program.school.name), standardized) > SEARCH_WEIGHT_SCHOOL ||
        distance(standardize(program.location.name), standardized) > SEARCH_WEIGHT_LOCATION
    ){
      return newProgram
    }else{
      newProgram.show = false
      return newProgram
    }
  }

  return newProgram
})

export const filterHighSchools = (programs, data) => programs.map(program => {
  const newProgram = {
    ...program,
    show: true
  }
  
  const filters = data.filters
  const gpa = data.gpa

  if(filters.diplom && program.special){
    newProgram.show = false
    return newProgram
  }

  if(filters.gotIn && gpa !== null && program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`] > gpa){
    newProgram.show = false
    return newProgram
  }

  if(filters.location.length !== 0 && !filters.location.includes(program.school.location.name)){
    newProgram.show = false
    return newProgram
  }

  if(filters.school.length !== 0 && !filters.school.includes(program.school.name)){
    newProgram.show = false
    return newProgram
  }

  if(filters.text !== ''){
    const standardized = standardize(filters.text)
    if(
      distance(standardize(program.name), standardized) > SEARCH_WEIGHT_NAME ||
        distance(standardize(program.school.name), standardized) > SEARCH_WEIGHT_SCHOOL ||
        distance(standardize(program.school.location.name), standardized) > SEARCH_WEIGHT_LOCATION
    ){
      return newProgram
    }else{
      newProgram.show = false
      return newProgram
    }
  }

  return newProgram
})
