import { MINIMUM_AMOUNT_OF_SUBJECTS } from '../constants/constants'
import i18next from './i18n'

const translationPrefix = 'pageBuilder.header.DiplomaInput.alerts.'
export const initialFormat = {
  motherLanguage: 0,
  mathematics: 0,
  otherLanguage: 0,
  officialLanguage: 0,
  otherSubject: 0,
  long: 0
}

export const changeFormat = (subject, isNew, currentFormat) => {
  const change = isNew ? 1 : -1

  const newDiplomFormat = {
    ...currentFormat,
    long: (subject.category === 'longLanguage' || subject.calculatorName === 'Matematiikka pitkä') ? currentFormat.long + change : currentFormat.long
  }

  if(subject.category === 'longLanguage' || subject.category === 'middleLanguage' || subject.category === 'shortLanguage') {
    if(subject.calculatorName.includes('Suomi') || subject.calculatorName.includes('Ruotsi')) {
      newDiplomFormat.officialLanguage = currentFormat.officialLanguage + change
    } else {
      newDiplomFormat.otherLanguage = currentFormat.otherLanguage + change
    }
  } else {
    newDiplomFormat[subject.category] = currentFormat[subject.category] + change
  }
  return newDiplomFormat
}

export const validateChoice = (newSubject, diplom) => {
  const category = newSubject.category

  switch (category) {
  case 'motherLanguage':
    return validateMotherLanguage(newSubject, diplom)
  case 'middleLanguage':
    return validateMiddleLanguage(newSubject, diplom)
  default:
    return {
      valid: true,
      message: []
    }
  }
}


const validateMiddleLanguage = (newSubject, diplom) => {
  const middleLanguages = diplom.filter(subject => subject.category === 'middleLanguage')
  const longLanguages = diplom.filter(subject => (subject.category === 'longLanguage'))
  const motherLanguages = diplom.filter(subject => (subject.category === 'motherLanguage'))
  const language = newSubject.calculatorName.split(' ')[0]
  const other = language === 'Suomi' ? 'Ruotsi' : 'Suomi'

  if (middleLanguages.length !== 0) {
    return {
      valid: false,
      message: [i18next.t(`${translationPrefix}noBothSecondOfficial`)]
    }
  }
  
  let contains = false

  longLanguages.forEach(subject => {
    if (subject.calculatorName.includes(other)) {
      contains = true
    }
  })

  motherLanguages.forEach(subject => {
    if (subject.calculatorName.includes(language)) {
      contains = true
    }
  })

  if (contains) {
    return {
      valid: false,
      message: [i18next.t(`${translationPrefix}noBothSecondOfficial`)]
    }
  }

  return {
    valid: true,
    message: []
  }

}

const validateMotherLanguage =  (newSubject, diplom)  => {
  const motherLanguages = diplom.filter(subject => subject.category === 'motherLanguage')
  const middleLanguages = diplom.filter(subject => subject.category === 'middleLanguage')
  const language = newSubject.calculatorName.includes('2') ? newSubject.calculatorName.split(' ')[0] : newSubject.calculatorName.split(' ')[1]

  let contains = false

  middleLanguages.forEach(subject => {
    if (subject.calculatorName.includes(language)) {
      contains = true
    }
  })

  if (contains) {
    return {
      valid: false,
      message: [i18next.t(`${translationPrefix}noBothSecondOfficial`)]
    }
  }

  if (motherLanguages.length === 0) {
    return {
      valid: true,
      message: []
    }
  } else if(motherLanguages.length > 1) {
    return {
      valid: false,
      message: [i18next.t(`${translationPrefix}tooManyMother`)]
    }
  }else if (newSubject.calculatorName.includes('saame')) {
    if(motherLanguages[0].calculatorName.includes('saame')) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}tooManySaame`)]
      }
    } else if (motherLanguages[0].calculatorName.includes('2')) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}noBothSecondOfficial`)]
      }
    } else {
      return {
        valid: true,
        message: []
      }
    }
  } else if(newSubject.calculatorName.includes('2')) {
    if(motherLanguages[0].calculatorName.includes('saame')) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}noBothSecondOfficial`)]
      }
    }

    if(motherLanguages[0].calculatorName.includes('2')) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}noBothS2R2`)]
      }
    }

    const language = newSubject.calculatorName.split(' ')[0]

    if (motherLanguages[0].calculatorName.includes(language)) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}noTwoSameMother`)]
      }
    } else {
      return {
        valid: true,
        message: []
      }
    }
    
  } else {
    const language = newSubject.calculatorName.split(' ')[1]
    if(motherLanguages[0].calculatorName.includes(language)) {
      return {
        valid: false,
        message: [i18next.t(`${translationPrefix}noTwoSameMother`)]
      }
    } else {
      return {
        valid: true,

        message: []
      }
    }
  }
}


export const validateFormat = format => {
  const errors = []
  
  let len = 0
  len = format.mathematics > 0 ? len + 1 : len
  len = format.otherSubject > 0 ? len + 1 : len
  len = format.otherLanguage > 0 ? len + 1 : len
  len = format.motherLanguage > 0 ? len + 1 : len
  len = (format.motherLanguage > 1 || format.officialLanguage > 0) ? len + 1 : len

  if(format.long < 1) {
    errors.push(i18next.t(`${translationPrefix}noLong`))
  }

  if(format.motherLanguage === 0) {
    errors.push(i18next.t(`${translationPrefix}noMother`))
  }

  if(len < MINIMUM_AMOUNT_OF_SUBJECTS) {
    errors.push(i18next.t(`${translationPrefix}wrongFormat`))
  }

  return {
    valid: errors.length === 0,
    message: errors
  }
}
