import React, { useState } from 'react'
import styles from './Hamburger.module.css'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import trinode from '../../static/images/trinode_grey.png'
import { useTranslation } from 'react-i18next'

const Hamburger = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const location = useLocation()

  const handleClick = () => {
    const body = document.getElementById('body')
    if (open) {
      setOpen(false)
      body.style.overflowY = 'scroll'
      window.scrollTo(0, 0)
    } else {
      setOpen(true)
      body.style.overflow = 'hidden'
    }
  }

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={`${styles.element} ${open && styles.rotate}`}></div>
      <div className={`${styles.element} ${open && styles.rotate}`}></div>
      {open &&
        <div className={styles.menu}>
          <div className={styles.linkContainer}>
            <h1>{t('navigation.title')}</h1>
            <Link to='/' className={`${location.pathname === '/' && styles.active} ${styles.navLink}`}>
              {t('navigation.home')}
            </Link>
            <Link to='/yliopistoon' className={`${location.pathname === '/yliopistoon' && styles.active} ${styles.navLink}`}>
              {t('navigation.university')}
            </Link>
            <Link to='/ammattikorkeaan' className={`${location.pathname === '/ammattikorkeaan' && styles.active} ${styles.navLink}`}>
              {t('navigation.amk')}
            </Link>
            <Link to='/lukioon' className={`${location.pathname === '/lukioon' && styles.active} ${styles.navLink}`}>
              {t('navigation.highschool')}
            </Link>
            <a href='https://www.trinode.fi' target='_blank' rel="noopener noreferral noreferrer" className={styles.navLink}>
              {t('navigation.about')}
            </a>
          </div>
          <div className={styles.bottomContainer}>
            <Button as={Link} to="/feedback" className="outline-primary mb-4">{t('navigation.feedback')}</Button>
            <div className={styles.trinode}>
              <img className={styles.trinodeLogo} src={trinode} />
              <p className="mb-0">Trinode Software Oy</p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Hamburger
