import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import CustomCheckbox from '../CustomCheckbox'
import MobileSelector from '../MobileSelector'
import styles from './CustomDropdown.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CustomDropdown = props => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.filters.dropdown'})
  const [open, setOpen] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [closeButton, setCloseButton] = useState(false)
  const [choises, setChoises] = useState({})
  const [count, setCount] = useState(0)
  const [placeholder, setPlaceHolder] = useState('')

  useEffect(() => {
    let count = 0
    Object.keys(choises).forEach(key => {
      if(choises[key]){
        count++
      }
    })
    setCount(count)
  }, [choises])

  const resetChoises = () => {
    const choisesObject = {}
    props.options.forEach(option => {
      const found = props.chosen.find(item => item === option)
      choisesObject[option] = found === undefined ? false : true
    })

    setChoises(choisesObject)
    handleClearText()
  }

  useEffect(() => {
    resetChoises()
  }, [props.chosen, props.options])

  const handleClearText = () => {
    setTextInput('')
    setCloseButton(false)
  }

  const handleTextField = event => {
    setTextInput(event.target.value)
    if (event.target.value === '') {
      setCloseButton(false)
    } else setCloseButton(true)
  }

  const handleCheckboxClick = item => {
    const newChoises = {
      ...choises,
      [item]: !choises[item]
    }
    setChoises(newChoises)
  }

  const handleSave = () => {
    setOpen(false)
    const choiseArray = Object.keys(choises).filter(key => choises[key])
    props.handleSave(choiseArray, props.name)
  }

  const handleReset = () => {
    const newChoises = {}
    props.options.forEach(option => {
      newChoises[option] = false
    })
    handleClearText()
    setChoises(newChoises)
  }

  const toggleDropdown = () => {
    if (open) {
      resetChoises()
    }
    setOpen(!open)
  }

  useEffect(() => {
    if (props.title === 'Paikkakunta') {
      setPlaceHolder('Syötä kunta...')
    } else setPlaceHolder('Syötä oppilaitos...')
  }, [])

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.element} onClick={toggleDropdown}>
        <div className={styles.dropdownRow}>
          <div className={styles.dropdownTextColumn}>
            <h5>{props.title}</h5>
            <p>{count === 0 ? t('all') : `${count} ${t('selected')}`}</p>
          </div>
          <div className={styles.dropdownChevronColumn}>
            {props.mobile
              ?   <p className="mb-0">{t('modify')}</p>
              :   <i className={`bi bi-chevron-${open ? 'up' : 'down'}`}></i>
            }
          </div>
        </div>
      </div>
      {props.mobile &&
        <MobileSelector
          open={open}
          textInput={textInput}
          closeButton={closeButton}
          handleClose={toggleDropdown}
          handleSave={handleSave}
          handleReset={handleReset}
          handleTextField={handleTextField}
          handleClearText={handleClearText}
          handleCheckboxClick={handleCheckboxClick}
          options={props.options}
          choises={choises}
          title={props.title}
          placeholder={placeholder}
        />
      }
      {open && !props.mobile &&
                <div className={styles.dropdown}>
                  <div className={`${styles.fixedTop} mb-2`}>
                    <div className={styles.flexRow}>
                      <h5 className={styles.grow}>{`${t('select')} ${props.title.toLowerCase()}`}</h5>
                      <Button className={`button-secondary ${styles.shrink}`} onClick={handleReset}>{t('clear')}</Button>
                    </div>
                    <div className={styles.searchContainer}>
                      <i className={`bi bi-search ${styles.searchColor}`}></i>
                      <input
                        type='text'
                        placeholder={placeholder}
                        value={textInput}
                        onChange={event => handleTextField(event)}
                        className={styles.search}
                      />
                      {
                        closeButton &&
                          <i onClick={handleClearText} className={`bi bi-x-circle-fill ${styles.closeButton}`}></i>
                      }
                    </div>
                  </div>
                  <div className={styles.transparentReverse}></div>
                  <div className={styles.contentWrapper}>
                    {props.options.filter(item => item.toLowerCase().includes(textInput.toLowerCase())).map(item => (
                      <div className={styles.contentElement} key={item}>
                        <CustomCheckbox checked={choises !== {} ? choises[item] : false} name={item} label={item} handleClick={handleCheckboxClick} disabled={false} />
                      </div>
                    ))}
                    {props.options.filter(item => item.toLowerCase().includes(textInput.toLowerCase())).length === 0 &&
                      <div className={styles.noResults}>
                        <i className="bi bi-emoji-frown text-secondary me-2"></i>
                        <p className="mb-0 text-secondary">{t('noResults')}</p>
                      </div>
                    }
                  </div>
                  <div className={styles.transparent}></div>
                  <div className={`${styles.fixedRow}`}>
                    <Button className={`${styles.saveButton} button-primary`} onClick={handleSave}>{t('save')}</Button>
                  </div>
                </div>
                
      }
      {open && !props.mobile && <div className={styles.canvas} onClick={toggleDropdown}></div>}
    </div>
    
  )
}

CustomDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  chosen: PropTypes.array.isRequired
}

export default CustomDropdown
