import React from 'react'
import { useSelector } from 'react-redux'
import ProgramCard from './ProgramCard'
import HighschoolCard from './HighschoolCard'
import { Container } from 'react-bootstrap'
import styles from './Listing.module.css'
import NoResults from './NoResults'
import { useLocation } from 'react-router-dom'
import AdSenseAd from '../AdSense/AdSenseAd'

const Listing = () => {
  const location = useLocation()

  // Constants
  const FIRST_AD_POSITION = 5
  const AD_REPEAT_INTERVAL = 50
  
  const itemsToShow = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.programs
    case '/ammattikorkeaan':
      return state.amks.programs
    case '/lukioon':
      return state.highSchools.programs
    default:
      return state.universities.programs
    }
  }).filter(program => program.show)

  return(
    <div className={`w-100 ${styles.listingWrapper}`}>
      <Container>
        {itemsToShow.length === 0
          ? <NoResults />
          :
          <>
            {
              location.pathname === '/lukioon'
                ? itemsToShow.map((program, index) => (
                  index === FIRST_AD_POSITION || (index - FIRST_AD_POSITION) % AD_REPEAT_INTERVAL === 0 ?
                    <>
                      <AdSenseAd />
                      <HighschoolCard key={program.id} program={program} />
                    </> : <HighschoolCard key={program.id} program={program}/>
                ))
                : itemsToShow.map((program, index) => (
                  index === FIRST_AD_POSITION || (index - FIRST_AD_POSITION) % AD_REPEAT_INTERVAL === 0 ?
                    <>
                      <AdSenseAd />
                      <ProgramCard key={program.id} program={program}/>
                    </> : <ProgramCard key={program.id} program={program}/>
                ))
            }
          </>
        }
      </Container>
    </div>
  )
}

export default Listing
