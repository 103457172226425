import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Row } from 'react-bootstrap'
import styles from './Progressbar.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Progressbar = ({
  title, gotIn, total, show
}) => {
  const { t } = useTranslation()
  const value = (show && total !== 0) ? Math.floor(gotIn/total * 100) : 0

  return(
    <div className={styles.element}>
      <h2 className="mb-4">{ title }</h2>
      <Row className={`justify-content-center ${!show && 'mb-5' }`}>
        <div className={styles.progressbar}>
          <CircularProgressbarWithChildren value={value} strokeWidth={12} styles={buildStyles({
            strokeLinecap: 'butt',
            pathTransitionDuration: 2,
            pathColor: '#639C26',
            trailColor: '#F6F6F6'
          })}>
            {show ?
              <div className={styles.progressRow}>
                <p className="mb-0"><strong>{value}%</strong></p>
              </div>
              :
              <div className={styles.progressRowNoDiplom}>
                <i className="bi bi-info-circle-fill me-2"></i>
                <p className="mb-0"><strong>{t('pageBuilder.header.noDiplom')}</strong></p>
              </div>
            }
          </CircularProgressbarWithChildren>
        </div>
      </Row>
      {show &&
        <div className={styles.resultRow}>
          <div className={styles.gotInColumn}>
            <h3 className="mb-1">{gotIn}</h3>
            <p className="mb-0">{t('pageBuilder.header.gotIn')}</p>
          </div>
          <div className={styles.totalColumn}>
            <h3 className="mb-1">{total}</h3>
            <p className="mb-0">{t('pageBuilder.header.total')}</p>
          </div>
        </div>
      }
    </div>
  )
}

Progressbar.propTypes = {
  title: PropTypes.string.isRequired,
  gotIn: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired
}

export default Progressbar
