import React, { useState, useEffect } from 'react'
import {
  Col, Button, Row, Container
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import homePageImage from '../../static/images/illustration.png'
// import HomePageCounter from './Counter'
// import HomePageLogos from './Logos'
import Opinkirjo from './Opinkirjo'
import styles from './Home.module.css'
import ContactUs from './Contact'
import { useTranslation } from 'react-i18next'
import { MOBILE_BREAKPOINT } from '../../constants/constants'

const Home = () => {
  const { t } = useTranslation()
  const isMobile = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  // const hideDetails = () => {
  //   const {innerHeight} = window
  //   return innerHeight <= HIDE_BREAKPOINT
  // }

  const [mobile, setIsMobile] = useState(isMobile())
  // const [detailsHidden, setDetailsHidden] = useState(hideDetails())

  const handleWindowResize = () => {
    setIsMobile(isMobile())
    // setDetailsHidden(hideDetails())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const views = [
    {
      name: t('home.cta.university'),
      url: '/yliopistoon'
    },
    {
      name: t('home.cta.amk'),
      url: '/ammattikorkeaan'
    },
    {
      name: t('home.cta.highschool'),
      url: '/lukioon'
    }
  ]

  return (
    <div>
      <Container>
        <Row className={styles.landingArea}>
          <Col md={6} className="px-0">
            <Row className={styles.preHeader}>
              <p className="text-primary mb-0"><strong>{t('home.slogan')}</strong></p>
            </Row>
            <Row className={styles.header}>
              <h1>Korkeakouluun.com</h1>
            </Row>
            <Row className={styles.text}>
              <p>{t('home.description')}</p>
            </Row>
            {/* {mobile && !detailsHidden && <HomePageCounter />} */}
            {mobile
              ?
              <div className={styles.element}>
                <h2 className="mb-3">{t('home.cta.title')}</h2>
                <div className={styles.navigate}>
                  {views.map((item, i) => (
                    <>
                      <Link className={styles.navigateRow} to={item.url}>
                        <div className={styles.edgeColumn}></div>
                        <div className={styles.linkColumn}>
                          <p className="mb-0">{item.name}</p>
                        </div>
                        <div className={styles.edgeColumn}>
                          <i className="bi bi-chevron-right text-primary"></i>
                        </div>
                      </Link>
                      {i !== 2 && <div className={styles.testBorder}></div>}
                    </>
                  ))}
                </div>
              </div>
              :
              <Row className="mx-0">
                <Button className="button-primary me-3 mb-3" as={Link} to={views[0].url}>{views[0].name}</Button>
                <Button className="button-primary me-3 mb-3" as={Link} to={views[1].url}>{views[1].name}</Button>
                <Button className="button-primary mb-3" as={Link} to={views[2].url}>{views[2].name}</Button>
              </Row>
            }
            {!mobile && <ContactUs />}
          </Col>
          {!mobile &&
            <Col md={6} className="text-end">
              <img src={homePageImage} className={styles.homeImage} />
              {/* <HomePageCounter /> */}
            </Col>
          }
        </Row>
        {/* <HomePageLogos /> */}
        {mobile && <ContactUs />}
      </Container>
      <Opinkirjo />
    </div>
  )
}

export default Home
