// import { filterHighSchoolPrograms, programSort } from './filterReducer'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { YEARS_HIGHSCHOOL } from '../constants/constants'
import highSchoolService from '../services/highSchools'
import { filterHighSchools } from '../utils/filter'
import { alphabeticalCompare } from '../utils/sort'

const latestYear = YEARS_HIGHSCHOOL[0]

const initialState = {
  programs: [],
  status: 'idle',
  error: null
}


export const fetchHighSchools = createAsyncThunk('highSchools/fetchHighSchools', async () => {
  const response = await highSchoolService.getHighSchoolPrograms()
  return response.map(program => ({
    ...program,
    show: true
  }))
})


const highestMarginCompare = (a, b) => {
  // We have same gpa for all programs so we can compare the minimumPoints
  const x = a.minimumPoints[0][`year_${latestYear}`]
  const y = b.minimumPoints[0][`year_${latestYear}`]
  if(a.special && b.special){
    return 0
  }
  if(a.special){
    return 1
  }
  if(b.special){
    return -1
  }
  if (x > y) { return 1 }
  if (x < y) { return -1 }
  return 0
}


const programSort = (programs, sortType) => {
  if(sortType === 'HIGHEST_MARGIN'){
    return programs.sort(highestMarginCompare)
  }else{
    return programs.sort(alphabeticalCompare)
  }
}


const highSchoolSlice = createSlice({
  name: 'highSchools',
  initialState,
  reducers: {
    resetHighSchoolFilters: state => {
      state.programs = state.programs.map(program => ({
        ...program,
        show: true
      }))
    },
    setHighSchoolFilters: (state, action) => {
      state.programs = filterHighSchools(state.programs, action.payload)
    },
    setHighSchoolSort: (state, action) => {
      state.programs = programSort(state.programs, action.payload)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHighSchools.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchHighSchools.fulfilled, (state, action) => {
        state.programs = programSort(action.payload, 'ALPHABETICAL')
        state.status = 'success'
      })
      .addCase(fetchHighSchools.rejected, (state, action) => {
        state.error = action.error
        state.status = 'error'
      })
  }
})

export const {
  resetHighSchoolFilters, setHighSchoolFilters, setHighSchoolSort
} = highSchoolSlice.actions
export default highSchoolSlice.reducer
