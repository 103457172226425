import React, {
  forwardRef, useImperativeHandle, useState
} from 'react'
import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styles from './WarningModal.module.css'
import { useTranslation } from 'react-i18next'


const WarningModal = forwardRef((props, ref) => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.header.DiplomaInput.warningModal'})
  const [show, setShow] = useState(false)

  useImperativeHandle(ref, () => ({
    setModalShow(value) {
      setShow(value)
    }
  }))

  const closeModal = () => {
    setShow(false)
    props.initializeIsChosen()
    props.handleClose(true)
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size='md'
    >
      <Modal.Header className={styles.customHeader}>
        <Modal.Title className={styles.title}>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.customBody}>
        <p className="mb-0 text-secondary">{t('description')}</p>
      </Modal.Body>
      <Modal.Footer className={styles.customFooter}>
        <Button className='button-secondary' onClick={() => setShow(false)}>{t('cancel')}</Button>
        <Button className='button-primary' onClick={() => closeModal()}>{t('confirm')}</Button>
      </Modal.Footer>
    </Modal>
  )
})

WarningModal.displayName = 'WarningModal'

WarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  initializeIsChosen: PropTypes.func.isRequired
}

export default WarningModal
