import React from 'react'
import styles from './Checkbox.module.css'
import PropTypes from 'prop-types'

const CustomCheckbox = props => (
  <div onClick={() => props.handleClick(props.name)} className={`${styles.element} ${props.disabled && styles.disabled}`}>
    <div className={props.checked ? styles.active : styles.box}>
      <i className="bi bi-check-lg d-flex"></i>
    </div>
    <div className={`${styles.label} ${props.disabled && styles.disabled}`}>
      {props.label}
    </div>
  </div>
)

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default CustomCheckbox
