import React from 'react'

const FeedbackPage = () => (
  <iframe
    title='Feedback form'
    src="https://docs.google.com/forms/d/e/1FAIpQLScAIN8B0n4zKxruqZGLT_QFkWcAk12kRB52_g26k3t_88qwRw/viewform?embedded=true"
    style={{
      width: '100vw',
      height: '100vh',
      frameBorder: '0',
      marginHeight: '0',
      marginWidth: '0'
    }}
  >
      Ladataan…
  </iframe>
)


export default FeedbackPage
