import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {  Provider } from 'react-redux'
import store from './store/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { install } from 'ga-gtag'
import { GA_TRACKING_ID } from './constants/constants'
import './utils/i18n'

install(GA_TRACKING_ID)

const app = document.getElementById('root')

if (app) {
  const root = createRoot(app)
  root.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
