import React, { useState, useEffect } from 'react'
import { Row, Container } from 'react-bootstrap'
import styles from './MobileContent.module.css'
import { YEARS_AMK, YEARS_UNIVERSITY } from '../../../../../../constants/constants'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import NoDiploma from '../../CardContent/NoDiploma'
import { useTranslation } from 'react-i18next'
import MinimumRequirement from '../../CardContent/MinimumRequirement'

const MobileContent = ({ program }) => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const [theme, setTheme] = useState('')
  const [icon, setIcon] = useState('')
  const [text, setText] = useState('')
  
  const location = useLocation()

  const results = useSelector(state => {
    if(location.pathname === '/yliopistoon'){
      return state.universities.results[program.id - 1]
    } else {
      return state.amks.results[program.id - 1]
    }
  })

  const years = location.pathname === '/yliopistoon' ? YEARS_UNIVERSITY : YEARS_AMK
  const diplomNotChosen = t('diplomNotChosen')
  const missingResults = `Pisteet puuttuvat vuodelta ${years[0]}`
  const gotInText = t('gotInText', {year: years[0]})
  const notInText = t('notInText', {year: years[0]})

  useEffect(() => {
    if (!results) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(diplomNotChosen)
    } else if (location.pathname === '/yliopistoon' && !program.minimumPoints[0][`year_${years[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (location.pathname ==='/ammattikorkeaan' && !program.minimumPointsMatriculation[0][`year_${years[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (results[`year_${years[0]}`].gotIn) {
      setTheme(styles.gotIn)
      setIcon('check-circle-fill')
      setText(gotInText)
    } else {
      setTheme(styles.notIn)
      setIcon('x-circle-fill')
      setText(notInText)
    }
  }, [results])


  return (
    <Container className={styles.cardContent}>
      <div className="mb-3">
        <Row className={styles.programName}>
          <h2>{program.name}</h2>
        </Row>
        <Row>
          <div className={styles.infoRow}>
            <div className={styles.subheader}>
              <i className="bi bi-house me-2"></i>
              <p className="mb-0">{program.school.name}</p>
            </div>
            <div className={styles.subheader}>
              <i className="bi bi-geo-alt me-2"></i>
              <p className="mb-0">{program.location.name}</p>
            </div>
          </div>
        </Row>
        {!program.diplom
          ?
          <NoDiploma />
          :
          <>
            <Row>
              <div className={`${styles.iconRow} ${theme}`}>
                <i className={`bi bi-${icon} ${styles.headerIcon}`}></i>
                <p className="mb-0">{text}</p>
              </div>
            </Row>
            <Row className={styles.previousYearsInfo}>
              <h3>{t('tableTitle')}</h3>
            </Row>
            {years.map(year => {
              const value = location.pathname === '/yliopistoon' ? program.minimumPoints[0][`year_${year}`] : program.minimumPointsMatriculation[0][`year_${year}`]
              return (
                <Row key={year}>
                  <div className={styles.year}>
                    <div>
                      <p className="mb-0 ms-2">{t('year')}</p>
                    </div>
                    <div className={styles.yearRow}>
                      <h4 className="ms-2">{year}</h4>
                    </div>
                    <div className={styles.white}>
                      <div className={styles.contentCol}>
                        <p className="mb-0">{t('gotIn')}</p>
                      </div>
                      <div className={styles.iconCol}>
                        <i className={`bi bi-${(results && value) ? (results[`year_${year}`].gotIn ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.icon}`}></i>
                      </div>
                    </div>
                    <div className={styles.gray}>
                      <div className={styles.contentCol}>
                        <p className="mb-0">{t('minimumRequirements')}</p>
                      </div>
                      <div className={styles.iconCol}>
                        <i className={`bi bi-${(results && value) ? (results[`year_${year}`].minimumRequirements ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.icon}`}></i>
                      </div>
                    </div>
                    <div className={styles.white}>
                      <div className={styles.contentCol}>
                        <p className="mb-0">{t('points')}</p>
                      </div>
                      <div className={styles.textCol}>
                        <div className={(results && value) ? (results[`year_${year}`].margin >= 0 ? styles.gotIn : styles.notIn) : styles.diplomNotSet}>
                          { (results && value) ? results[`year_${year}`].points : (value ? t('noDiplom') : 'Pisteet puuttuvat')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.gray}>
                      <div className={styles.contentCol}>
                        <p className="mb-0">{t('requiredPoints')}</p>
                      </div>
                      <div className={value ? styles.pointCol : styles.diplomNotSet}>
                        {value ? value : 'Pisteet puuttuvat'}
                      </div>
                    </div>
                    <div className={styles.white}>
                      <div className={styles.contentCol}>
                        <p className="mb-0">{t('margin')}</p>
                      </div>
                      <div className={styles.textCol}>
                        <div className={(results && value) ? (results[`year_${year}`].margin >= 0 ? styles.gotIn : styles.notIn) : styles.diplomNotSet}>
                          { (results && value) ? `${results[`year_${year}`].margin} %` : (value ? t('noDiplom') : 'Pisteet puuttuvat')}
                        </div>
                      </div>
                    </div>
                    <h5 className="ms-2">{t('minimumRequirements')}</h5>
                    <div className="ms-2">
                      {year === '2020'
                        ?
                        <div className={`${styles.unknown} d-flex`}>
                          <i className={`bi bi-info-circle-fill ${styles.headerIcon}`}></i>
                          <p className="mb-0">{t('2020MinReq')}</p>
                        </div>
                        : <MinimumRequirement program={program} year={year} />
                      }
                    </div>
                  </div>
                </Row>
              )
            })
            }
          </>
        }
      </div>
    </Container>
  )
}

MobileContent.propTypes = {
  program: PropTypes.object.isRequired
}

export default MobileContent
