import React from 'react'
import Advertisement from './Advertisement'
import PageFilters from './Filters'
import PageHeader from './Header'
import ResultsAmountAndSort from './ResultsAmountAndSort'
import Listing from './Listing'
import Loading from './Loading'


const PageBuilder = () => (
  <>
    <Loading />
    <Advertisement />
    <PageHeader />
    <PageFilters />
    <ResultsAmountAndSort />
    <Listing />
  </>
)

export default PageBuilder
