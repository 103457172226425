import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Button, Row, Col, Container
} from 'react-bootstrap'
import korkeakouluunLogo from '../static/images/korkeakouluun_logo.png'
import styles from './Navstyles.module.css'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import { LOCALES_AVAILABLE } from '../../constants/constants'

const NavBar = props => {
  const { t } = useTranslation()
  const location = useLocation()

  const handleLocaleSwitch = () => {
    props.changeLanguage(props.language === 'fi' ? 'en' : 'fi')
  }

  return(
    <div className={`d-flex justify-content-center w-100 ${styles.navbar}`}>
      <Container>
        <Row className="py-3">
          <Col md={2}>
            <Link to='/'>
              <img
                src={korkeakouluunLogo}
                height="40"
                alt="Korkeakouluun logo"
              />
            </Link>
          </Col>
          <Col md={8} className="text-center justify-content-center d-flex">
            <div className={`d-flex justify-content-center align-items-center ${styles.navContent}`}>
              <Link to='/' className={`${location.pathname === '/' && styles.active} ${styles.navLink}`}>
                {t('navigation.home')}
              </Link>
              <Link to='/yliopistoon' className={`${location.pathname === '/yliopistoon' && styles.active} ${styles.navLink}`}>
                {t('navigation.university')}
              </Link>
              <Link to='/ammattikorkeaan' className={`${location.pathname === '/ammattikorkeaan' && styles.active} ${styles.navLink}`}>
                {t('navigation.amkShort')}
              </Link>
              <Link to='/lukioon' className={`${location.pathname === '/lukioon' && styles.active} ${styles.navLink}`}>
                {t('navigation.highschool')}
              </Link>
              <a href='https://www.trinode.fi' target='_blank' rel="noopener noreferral noreferrer" className={styles.navLink}>
                {t('navigation.about')}
              </a>
              {LOCALES_AVAILABLE && <button onClick={handleLocaleSwitch}>{props.language}</button>}
            </div>
          </Col>
          <Col md={2} className="text-end">
            <Button as={Link} to="/feedback" className="outline-primary">{t('navigation.feedback')}</Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

NavBar.propTypes = {
  changeLanguage: propTypes.func,
  language: propTypes.string
}

export default NavBar
