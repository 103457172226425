import React, { useState, useEffect } from 'react'
import {
  Col, Row, Container
} from 'react-bootstrap'
import Opintopolkuun from './Opintopolkuun'
import NoDiploma from './NoDiploma'
import styles from './CardContent.module.css'
import { YEARS_AMK, YEARS_UNIVERSITY } from '../../../../../constants/constants'
import { useSelector } from 'react-redux'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MinimumRequirement from './MinimumRequirement'

const CardContent = ({ program }) => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const location = useLocation()
  
  const [theme, setTheme] = useState('')
  const [icon, setIcon] = useState('')
  const [text, setText] = useState('')
  const [minimumRequirementsExist, setExist] = useState(true)

  const years = location.pathname === '/yliopistoon' ? YEARS_UNIVERSITY : YEARS_AMK

  const results = useSelector(state => {
    if(location.pathname === '/yliopistoon'){
      return state.universities.results[program.id - 1]
    } else if (location.pathname === '/ammattikorkeaan') {
      return state.amks.results[program.id - 1]
    } else return null
  })

  const diplomNotChosen = t('diplomNotChosen')
  const missingResults = `Pisteet puuttuvat vuodelta ${years[0]}`
  const gotInText = t('gotInText', {year: years[0]})
  const notInText = t('notInText', {year: years[0]})

  useEffect(() => {
    checkForMinimumRequirements(years)
  }, [location.pathname])


  useEffect(() => {
    checkForMinimumRequirements()
    if (!results) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(diplomNotChosen)
    } else if (location.pathname === '/yliopistoon' && !program.minimumPoints[0][`year_${years[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (location.pathname ==='/ammattikorkeaan' && !program.minimumPointsMatriculation[0][`year_${years[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (results[`year_${years[0]}`] && results[`year_${years[0]}`].gotIn) {
      setTheme(styles.gotIn)
      setIcon('check-circle-fill')
      setText(gotInText)
    } else {
      setTheme(styles.notIn)
      setIcon('x-circle-fill')
      setText(notInText)
    }
  }, [results])


  const checkForMinimumRequirements = (yearsToCheck = years) => {
    // We don't have minimum requirements for year 2020 so let's remove it.
    const filteredArray = yearsToCheck.filter(year => year !== '2020')
    const rtrnArr = filteredArray.map(year => program.minimumRequirements[0][`year_${year}`])
    if(!rtrnArr.every(i => i === null)) setExist(true)
    else setExist(false)
  }

  return (
    <Container className={styles.cardContent}>
      {!program.diplom
        ? <NoDiploma />
        :
        <div className="mb-4">
          <Row>
            <div className={`${styles.iconRow} ${theme}`}>
              <i className={`bi bi-${icon} ${styles.headerIcon}`}></i>
              <p className="mb-0">{text}</p>
            </div>
          </Row>
          <Row className={styles.previousYearsInfo}>
            <h3>{t('tableTitle')}</h3>
          </Row>
          <Row className={styles.headerRow}>
            <Col xs={1} className={styles.headerColumn}>
              {t('year')}
            </Col>
            <Col className="text-center">
              {t('gotIn')}
            </Col>
            <Col className="text-center">
              {t('minimumRequirements')}
            </Col>
            <Col className="text-center">
              {t('points')}
            </Col>
            <Col className="text-center">
              {t('requiredPoints')}
            </Col>
            <Col className="text-center">
              {t('margin')}
            </Col>
          </Row>
          {years.map(year => {
            const value = location.pathname === '/yliopistoon' ? program.minimumPoints[0][`year_${year}`] : program.minimumPointsMatriculation[0][`year_${year}`]
            return (
              <Row className={styles.tableRow} key={year}>
                <Col xs={1} className={styles.yearColumn}>
                  {year}
                </Col>
                <Col className="text-center">
                  <i className={`bi bi-${(results && value) ? (results[`year_${year}`].gotIn ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.headerIcon}`}></i>
                </Col>
                <Col className="text-center">
                  <i className={`bi bi-${(results && value) ? (results[`year_${year}`].minimumRequirements ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.headerIcon}`}></i>
                </Col>
                <Col className={`text-center ${(results && value) ? (results[`year_${year}`].margin >= 0 ? styles.gotIn : styles.notIn) : styles.diplomNotSetTable}`}>
                  <p className="mb-0">{results ? results[`year_${year}`].points : t('noDiplom')}</p>
                </Col>
                <Col className={`text-center ${value ? styles.tableTextNormal : styles.diplomNotSetTable}`}>
                  <p className="mb-0">{value ? value : 'Pisteet puuttuvat'}</p>
                </Col>
                <Col className={`text-center ${(results && value) ? (results[`year_${year}`].margin >= 0 ? styles.gotIn : styles.notIn) : styles.diplomNotSetTable}`}>
                  <p className="mb-0">{(results && value) ? `${results[`year_${year}`].margin} %` : (value ? t('noDiplom') : 'Pisteet puuttuvat')}</p>
                </Col>
              </Row>
            )
          })
          }
          <Row className={`${styles.previousYearsInfo} mt-3`}>
            <h3>{t('minReqTitle')}</h3>
          </Row>
          {minimumRequirementsExist
            ?
            <div>
              <Row className={styles.headerRow}>
                <Col xs={1} className={styles.headerColumn}>
                  {t('year')}
                </Col>
                <Col>
                  {t('minReqColTitle')}
                </Col>
              </Row>
              {years.map(year => (
                <Row className={`${styles.tableRow}`} key={year}>
                  <Col xs={1} className={styles.yearColumn}>
                    {year}
                  </Col>
                  <Col>
                    {year === '2020'
                      ?
                      <div className={`${styles.yearDisabled} d-flex`}>
                        <i className={`bi bi-info-circle-fill ${styles.headerIcon}`}></i>
                        <p className="mb-0">{t('2020MinReq')}</p>
                      </div>
                      :
                      <MinimumRequirement program={program} year={year}/>
                    }
                  </Col>
                </Row>
              ))}
            </div>
            :
            <div className={`${styles.diplomNotSet} ${styles.noRequirementsRow}`}>
              <i className={`bi bi-question-circle-fill ${styles.headerIcon}`}></i>
              <p className="mb-0">{t('noMinReqAtAll')}</p>
            </div>
          }
        </div>
      }
      <Opintopolkuun url={program.url} />
    </Container>
  )
}

CardContent.propTypes = {
  program: PropTypes.object.isRequired
}

export default CardContent
