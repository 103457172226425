import React, { useState, useEffect } from 'react'
import {
  Col, Row, Container
} from 'react-bootstrap'
import SpecialHighschool from './SpecialHighschool'
import styles from './HighschoolContent.module.css'
import {
  TEN, THOUSAND, YEARS_HIGHSCHOOL
} from '../../../../../constants/constants'
import { useSelector } from 'react-redux'
import Opintopolkuun from '../../ProgramCard/CardContent/Opintopolkuun'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const HighschoolContent = ({ program }) => {

  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const [theme, setTheme] = useState('')
  const [icon, setIcon] = useState('')
  const [text, setText] = useState('')

  const diplomNotChosen = t('highschool.diplomNotChosen')
  const gotInText = t('gotInText', {year: YEARS_HIGHSCHOOL[0]})
  const notInText = t('notInText', {year: YEARS_HIGHSCHOOL[0]})
  const missingResults = `Pisteet puuttuvat vuodelta ${YEARS_HIGHSCHOOL[0]}`

  const gpa = useSelector(state => state.diplom.gpa)
  
  useEffect(() => {
    if (gpa === null) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(diplomNotChosen)
    } else if (!program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (gpa >= program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`]) {
      setTheme(styles.gotIn)
      setIcon('check-circle-fill')
      setText(gotInText)
    } else {
      setTheme(styles.notIn)
      setIcon('x-circle-fill')
      setText(notInText)
    }
  }, [gpa])

  return (
    <Container className={styles.cardContent}>
      {program.special
        ? <SpecialHighschool />
        :
        <div className="mb-4">
          <Row>
            <div className={`${styles.iconRow} ${theme}`}>
              <i className={`bi bi-${icon} ${styles.headerIcon}`}></i>
              <p className="mb-0">{text}</p>
            </div>
          </Row>
          <Row className={styles.previousYearsInfo}>
            <h3>{t('tableTitle')}</h3>
          </Row>
          <Row className={styles.headerRow}>
            <Col xs={1} className={styles.headerColumn}>
              {t('year')}
            </Col>
            <Col className="text-center">
              {t('gotIn')}
            </Col>
            <Col className="text-center">
              {t('highschool.requiredGPA')}
            </Col>
            <Col className="text-center">
              {t('margin')}
            </Col>
          </Row>
          {YEARS_HIGHSCHOOL.map(year => {
            const points = program.minimumPoints[0][`year_${year}`]
            const gotIn = gpa >= points
            const margin = Math.round((gpa - points) / points * THOUSAND) / TEN
            return (
              <Row className={styles.tableRow} key={year}>
                <Col xs={1} className={styles.yearColumn}>
                  {year}
                </Col>
                <Col className="text-center">
                  <i className={`bi bi-${(gpa !== null && points) ? (gotIn ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.headerIcon}`}></i>
                </Col>
                <Col className={`text-center ${points ? styles.tableTextNormal : styles.diplomNotSet}`}>
                  {points ? points : 'Keskiarvo puuttuu'}
                </Col>
                <Col className={`text-center ${(gpa !== null && points) ? (gotIn ? styles.gotIn : styles.notIn) : styles.diplomNotSet}`}>
                  { (gpa !== null && points) ? `${margin} %` : (points ? t('highschool.noGPA') : 'Keskiarvo puuttuu') }
                </Col>
              </Row>
            )
          })}
        </div>
      }
      <Opintopolkuun />
    </Container>
  )
}

HighschoolContent.propTypes = {
  program: PropTypes.object.isRequired
}

export default HighschoolContent
