import React from 'react'
import opinkirjo from '../../static/images/mooc-korkeakouluun2.png'
import {
  Button, Container, Row, Col
} from 'react-bootstrap'
import styles from './Opinkirjo.module.css'

const Opinkirjo = () => (
  <div className={styles.wrapper}>
    <Container>
      <Row className={styles.alignCenter}>
        <Col md={5} className={styles.colAlign}>
          <h1 className="mb-3">Kurkista yliopisto-opiskeluun</h1>
          <p className="mb-4">Tiedelukutaidon perusteet on maksuton verkkokurssi, jonka ovat tuottaneet yhteistyössä Opinkirjo ja Helsingin yliopisto. Jopa 80 % kurssin suorittaneista kokee saaneensa lisää tietoa korkeakoulumaailmasta ja -opinnoista, ja 77 % tutustuneensa uusiin aloihin. Saat kurssista myös opintopisteitä!</p>
          <a href='https://tiedelukutaito.mooc.fi/' target="_blank" rel="noopener noreferrer">
            <Button className="button-primary">Tutustu kurssiin!</Button>
          </a>
        </Col>
        <Col md={7} className={styles.colAlign}>
          <img src={opinkirjo} className={styles.image} />
        </Col>
      </Row>
    </Container>
  </div>
)

export default Opinkirjo
