import React, {
  useEffect, useRef, useState
} from 'react'
import {
  Button, Row, Col
} from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import styles from './InputOffcanvas.module.css'
import Subject from './Subject'
import GradeCard from './GradeCard'
import Notification from './Notification'
import matriculationSubjects from '../../../subjects/matriculationSubjects'
import WarningModal from './WarningModal'
import {
  validateChoice, validateFormat, changeFormat, initialFormat
} from '../../../utils/validate'
import { useDispatch, useSelector } from 'react-redux'
import { calculateUniversities } from '../../../reducers/universitiesReducer'
import { calculateAMKResults } from '../../../reducers/AMKReducer'
import { setHighschoolDiplom } from '../../../reducers/diplomReducer'
import DiplomaHoverElement from '../DiplomaHoverElement'
import {
  SEVEN_SECONDS,
  HALF_SECOND, MOBILE_BREAKPOINT
} from '../../../constants/constants'
import { useTranslation } from 'react-i18next'

const DiplomaInput = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pageBuilder.header.DiplomaInput' })
  const dispatch = useDispatch()

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [show, setShow] = useState(false)
  const [view, setView] = useState('subjects')
  const [writtenSubjects, setWrittenSubjects] = useState([])
  const [isChosen, setIsChosen] = useState({})
  const [mayClose, setMayClose] = useState(true)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationTimeout, setNotificationTimeout] = useState(null)
  const [notificationMessage, setNotificationMessage] = useState([])
  const [diplomFormat, setDiplomFormat] = useState(initialFormat)
  const [isValid, setIsValid] = useState(true)

  const savedDiplom = useSelector(state => state.diplom.diplom)
  const modalRef = useRef(null)
    

  const initializeIsChosen = () => {
    const initIsChosen = {}
    let newFormat = initialFormat
    matriculationSubjects.forEach(subject => {
      if(savedDiplom.find(item => (item.diplomaName === subject.diplomaName))) {
        initIsChosen[subject.diplomaName] = true
        newFormat = changeFormat(subject, true, newFormat)
      }else {
        initIsChosen[subject.diplomaName] = false
      }
    })
    setIsChosen(initIsChosen)
    setWrittenSubjects(savedDiplom)
    setDiplomFormat(newFormat)
  }

  useEffect(() => {
    initializeIsChosen()
  }, [savedDiplom])


  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])


  const handleClose = (forceClose = false) => {
    if(!mayClose && !forceClose) {
      modalRef.current.setModalShow(true)
    }else{
      setShow(false)
      closeNotification()
      setTimeout(() => {
        setView('subjects')
      }, HALF_SECOND)
    }
  }

  const handleNotification = message => {
    if(notificationTimeout !== null) {
      clearTimeout(notificationTimeout)
    }
    setShowNotification(true)
    setNotificationMessage(message)
    const newTimeout = setTimeout(() => {
      setShowNotification(false)
    }, SEVEN_SECONDS + HALF_SECOND)

    setNotificationTimeout(newTimeout)
  }

  const closeNotification = () => {
    if(notificationTimeout !== null) {
      clearTimeout(notificationTimeout)
      setNotificationTimeout(null)
    }
    setShowNotification(false)
    setNotificationMessage('')
  }


  const handleClick = diplomaName => {
    const subject = matriculationSubjects.find(subject => subject.diplomaName === diplomaName)
    const changedObject = {
      diplomaName: diplomaName,
      calculatorName: subject.calculatorName,
      grade: null,
      category: subject.category
    }

    if (!isChosen[diplomaName]) {
      const validationResult = validateChoice(changedObject, writtenSubjects)
      if(!validationResult.valid) {
        handleNotification(validationResult.message)
        return
      }
    }

    const chosen = !isChosen[diplomaName]
    const newIsChosen = {
      ...isChosen,
      [diplomaName]: chosen
    }

    setMayClose(false)
    setIsChosen(newIsChosen)
    setDiplomFormat(changeFormat(changedObject, chosen, diplomFormat))

    if (chosen) {
      setWrittenSubjects([...writtenSubjects, changedObject])
    } else {
      setWrittenSubjects(writtenSubjects.filter(elem => elem.diplomaName !== diplomaName))
    }
  }

  const setGrade = (item, newGrade) => {
    setMayClose(false)
    const newItem = {
      ...item,
      grade: newGrade
    }

    const newSubjects = writtenSubjects.map(elem => elem.diplomaName !== item.diplomaName ? elem : newItem)
    setWrittenSubjects(newSubjects)
    const hasGrades = newSubjects.filter(item => item.grade === null).length === 0
    setIsValid(hasGrades)
  }

  const handleDelete = item => {
    setMayClose(false)
    const newIsChosen = {
      ...isChosen,
      [item.diplomaName]: false
    }
    const newFormat = changeFormat(item, false, diplomFormat)
    setDiplomFormat(newFormat)

    const validationResult = validateFormat(newFormat)
    if(!validationResult.valid) {
      handleNotification(validationResult.message)
      setIsValid(false)
    }

    setIsChosen(newIsChosen)
    setWrittenSubjects(writtenSubjects.filter(elem => elem.diplomaName !== item.diplomaName))
    
  }

  const calculate = () => {
    const validationResult = validateFormat(diplomFormat)
    if(!validationResult.valid) {
      handleNotification(validationResult.message)
      return
    }
    const diplomToCalculator = writtenSubjects.map(item => [item.calculatorName, item.grade])
    const hasGrades = diplomToCalculator.filter(item => item[1] === null).length === 0
    if(!hasGrades) {
      handleNotification([t('alerts.notAllGrades')])
      return
    }

    dispatch(calculateUniversities(diplomToCalculator))
    dispatch(calculateAMKResults(diplomToCalculator))
    dispatch(setHighschoolDiplom(writtenSubjects))
    localStorage.setItem('DiplomSubjects', JSON.stringify(writtenSubjects))
    setMayClose(true)
    handleClose(true)
  }


  const handleShow = () => setShow(true)

  const next = () => {
    const validationResult = validateFormat(diplomFormat)
    if(!validationResult.valid) {
      handleNotification(validationResult.message)
      return
    } else {
      const hasGrades = writtenSubjects.filter(item => item.grade === null).length === 0
      scrollToTop()
      setView('grades')
      setIsValid(hasGrades)
    }
  }

  const scrollToTop = () => document.getElementById('inputOffCanvasBody').scrollTo(0, 0)

  const back = () => {
    scrollToTop()
    setView('subjects')
  }

  const fullWidth = 12
  const half = 6

  return (
    <>
      {showNotification && <Notification title={t('notificationTitle')} text={notificationMessage} close={closeNotification} />}
      <Button onClick={handleShow} className="button-primary">
        {t('title')}
      </Button>
      <WarningModal handleClose={handleClose} initializeIsChosen={initializeIsChosen} ref={modalRef}/>
      <DiplomaHoverElement handleShow={handleShow} />
      <Offcanvas show={show} onHide={handleClose} placement={windowSize > MOBILE_BREAKPOINT ? 'end' : 'bottom'} className={styles.element}>
        <Offcanvas.Header className={styles.header}>
          <Offcanvas.Title className={styles.title}>{t('title')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.body} id='inputOffCanvasBody'>
          <Row className='mx-0'>
            <p className="mb-0 text-secondary">{view === 'subjects' ? t('description.subjects') : t('description.grades')}</p>
          </Row>
          { view === 'subjects' ?
            <div className={styles.bodyContent}>
              <Row className={styles.subjectRow}>
                <h2>{t('subjects.motherLanguages')}</h2>
                {matriculationSubjects.map(item => {
                  if(item.category === 'motherLanguage'){
                    return(
                      <Subject key={`${item.name}-motherLanguage`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  } else return null
                })}
              </Row>
              <Row className={styles.subjectRow}>
                <h2>{t('subjects.mathematics')}</h2>
                {matriculationSubjects.map(item => {
                  if(item.category === 'mathematics') {
                    return (
                      <Subject key={`${item.name}-mathematics`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  }else return null
                })}
              </Row>
              <Row className={styles.subjectRow}>
                <h2>{t('subjects.languages')}</h2>
                <h3 className="mt-1">{t('subjects.longLanguages')}</h3>
                {matriculationSubjects.map(item => {
                  if (item.category === 'longLanguage') {
                    return (
                      <Subject key={`${item.name}-longLanguage`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  } else return null
                })}
                <h3 className="mt-2">{t('subjects.middleLanguages')}</h3>
                {matriculationSubjects.map(item => {
                  if(item.category === 'middleLanguage'){
                    return(
                      <Subject key={`${item.name}-middleLanguage`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  } else return null
                })}
                <h3 className="mt-2">{t('subjects.shortLanguages')}</h3>
                {matriculationSubjects.map(item => {
                  if(item.category === 'shortLanguage') {
                    return (
                      <Subject key={`${item.name}-shortLanguage`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  } else return null
                })}
              </Row>
              <Row className={styles.subjectRow}>
                <h2>{t('subjects.others')}</h2>
                {matriculationSubjects.map(item => {
                  if(item.category === 'otherSubject') {
                    return (
                      <Subject key={`${item.name}-otherSubject`} name={item.name} diplomaName={item.diplomaName} isChosen={isChosen[item.diplomaName]} handleClick={handleClick} />
                    )
                  } else return null
                })}
              </Row>
            </div>
            :
            <div className={styles.bodyContent}>
              <Row className={styles.ownDiplom}>
                <h2>{t('myDiplom')}</h2>
              </Row>
              {writtenSubjects.map(item => (
                <GradeCard key={item.diplomaName} item={item} setGrade={setGrade} handleDelete={handleDelete} width={windowSize}/>
              ))}
            </div>
          }
          <div className={styles.transparent}></div>
          <Row className={styles.controlRow}>
            {windowSize > MOBILE_BREAKPOINT &&
              <Col xs={6}>
                <Button className="outline-primary" onClick={view === 'subjects' ? () => handleClose() : back}>
                  {view === 'subjects'
                    ? t('close')
                    :
                    <div className={styles.buttonRow}>
                      <i className="bi bi-chevron-left d-flex me-2"></i>
                      <p className='mb-0 d-flex'>{t('back')}</p>
                    </div>
                  }
                </Button>
              </Col>
            }
            <Col xs={windowSize > MOBILE_BREAKPOINT ? half : fullWidth} className="text-end">
              <Button className={`button-primary ${view === 'grades' && !isValid && 'button-disabled'}`} onClick={view === 'subjects' ? next : calculate}>
                {view === 'subjects'
                  ?
                  <div className={styles.buttonRow}>
                    <p className='mb-0 d-flex me-2'>{t('next')}</p>
                    <i className="bi bi-chevron-right d-flex"></i>
                  </div>
                  : t('calculate')
                }
              </Button>
            </Col>
          </Row>
          <div className={styles.close}>
            {windowSize > MOBILE_BREAKPOINT
              ?
              <Button className="button-secondary" onClick={() => handleClose()}>Sulje</Button>
              :
              <Button
                className="button-secondary" onClick={view === 'subjects' ? () => handleClose() : back }>
                {view === 'subjects'
                  ? t('close')
                  :
                  <div className={styles.backButton}>
                    <i className="bi bi-chevron-left d-flex me-1"></i>
                    <p className='mb-0 d-flex'>{t('mobileBack')}</p>
                  </div>
                }
              </Button>
            }
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default DiplomaInput
