import React from 'react'
import PropTypes from 'prop-types'
import styles from './DeveloperCard.module.css'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


const DeveloperCard = props => {
  const { t } = useTranslation()
  return (
    <div className={styles.cardStyle}>
      <img src={props.url} className={styles.imgStyle} />
      <h3>{props.name}</h3>
      <p className="text-secondary mb-0">{props.votes} {t('errorPage.voting.votes')}</p>
      <div className={styles.buttonRow}>
        <Button className="button-primary" onClick={() => props.handleAdd(props.id, props.votes + 1)}>{t('errorPage.voting.vote')}</Button>
      </div>
    </div>
  )
}

DeveloperCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
  handleAdd: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
}

export default DeveloperCard
