import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  diplom: [],
  gpa: null
}


const diplomSlice = createSlice({
  name: 'diplom',
  initialState,
  reducers: {
    resetDiplom: () => initialState,
    setHighschoolDiplom: (state, action) => {
      state.diplom = action.payload
    },
    setGPA: (state, action) => {
      state.gpa = action.payload
    },
    setDiplom: (state, action) => action.payload
  }
})

export const {
  resetDiplom, setHighschoolDiplom, setGPA, setDiplom
} = diplomSlice.actions
export default diplomSlice.reducer
