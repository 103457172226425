// const motherLanguages = [
//   {name: "Suomi", diplomaName: "Äidinkieli - suomi", calculatorName: "Äidinkieli Suomi", category: "motherLanguage"}, 
//   {name:"Ruotsi", diplomaName: "Äidinkieli - ruotsi", calculatorName: "Äidinkieli Ruotsi", category: "motherLanguage"}, 
//   {name: "Suomi toisena kielenä", diplomaName: "Suomi toisena kielenä", calculatorName: "Suomi 2", category: "motherLanguage"}, 
//   {name:"Ruotsi toisena kielenä", diplomaName:"Ruotsi toisena kielenä", calculatorName: "Ruotsi 2", category: "motherLanguage"}, 
//   {name:"Pohjoissaame", diplomaName:"Äidinkieli - pohjoissaame", calculatorName: "Äidinkieli Pohjoissaame", category: "motherLanguage"}, 
//   {name:"Koltansaame", diplomaName:"Äidinkieli - koltansaame", calculatorName: "Äidinkieli Koltansaame", category: "motherLanguage"}, 
//   {name:"Inarinsaame", diplomaName:"Äidinkieli - inarinsaame", calculatorName: "Äidinkieli Inarinsaame", category: "motherLanguage"}
// ]

// const mathematics = [
//   {name: "Pitkä matematiikka", diplomaName: "Pitkä matematiikka", calculatorName: "Matematiikka pitkä", category: "mathematics"}, 
//   {name: "Lyhyt matematiikka", diplomaName: "Lyhyt matematiikka", calculatorName: "Matematiikka lyhyt", category: "mathematics"}
// ]

// const longLanguages = [
//   {name: "Englanti", diplomaName: "Englanti - pitkä", calculatorName: "Englanti pitkä", category: "longLanguage"},
//   {name: "Toinen kotimainen kieli - suomi", diplomaName: "Toinen kotimainen kieli, suomi - pitkä", calculatorName: "Suomi pitkä", category: "longLanguage"}, 
//   {name: "Toinen kotimainen kieli - ruotsi", diplomaName: "Toinen kotimainen kieli, ruotsi - pitkä", calculatorName: "Ruotsi pitkä", category: "longLanguage"}, 
//   {name: "Venäjä", diplomaName: "Venäjä - pitkä", calculatorName: "Venäjä pitkä", category: "longLanguage"}, 
//   {name: "Saksa", diplomaName: "Saksa - pitkä", calculatorName: "Saksa pitkä", category: "longLanguage"}, 
//   {name: "Ranska", diplomaName: "Ranska - pitkä", calculatorName: "Ranska pitkä", category: "longLanguage"}, 
//   {name: "Espanja", diplomaName: "Espanja - pitkä", calculatorName: "Espanja pitkä", category: "longLanguage"}
// ]

// const middleLanguages = [
//   {name: "Toinen kotimainen kieli - ruotsi", diplomaName: "Toinen kotimainen kieli, ruotsi - keskipitkä", calculatorName: "Ruotsi keskipitkä", category: "middleLanguage"}, 
//   {name: "Toinen kotimainen kieli - suomi", diplomaName: "Toinen kotimainen kieli, suomi - keskipitkä", calculatorName: "Ruotsi keskipitkä", category: "middleLanguage"}
// ]

// const shortLanguages = [
//   {name: "Venäjä", diplomaName: "Venäjä - lyhyt", calculatorName: "Venäjä lyhyt", category: "shortLanguage"}, 
//   {name: "Saksa", diplomaName: "Saksa - lyhyt", calculatorName: "Saksa lyhyt", category: "shortLanguage"}, 
//   {name: "Ranska", diplomaName: "Ranska - lyhyt", calculatorName: "Ranska lyhyt", category: "shortLanguage"},
//   {name: "Englanti", diplomaName: "Englanti - lyhyt", calculatorName: "Englanti lyhyt", category: "shortLanguage"}, 
//   {name: "Espanja", diplomaName: "Espanja - lyhyt", calculatorName: "Espanja lyhyt", category: "shortLanguage"}, 
//   {name: "Italia", diplomaName: "Italia - lyhyt", calculatorName: "Italia lyhyt", category: "shortLanguage"}, 
//   {name: "Latina", diplomaName: "Latina - lyhyt", calculatorName: "Latina lyhyt", category: "shortLanguage"}, 
//   {name: "Portugali", diplomaName: "Portugali - lyhyt", calculatorName: "Portugali lyhyt", category: "shortLanguage"}, 
//   {name: "Pohjoissaame", diplomaName: "Pohjoissaame - lyhyt", calculatorName: "Pohjoissaame lyhyt", category: "shortLanguage"}, 
//   {name: "Koltansaame", diplomaName: "Koltansaame - lyhyt", calculatorName: "Koltansaame lyhyt", category: "shortLanguage"}, 
//   {name: "Inarinsaame", diplomaName: "Inarinsaame - lyhyt", calculatorName: "Inarinsaame lyhyt", category: "shortLanguage"}
// ]

// const otherSubjects = [
//   {name: "Yhteiskuntaoppi", diplomaName: "Yhteiskuntaoppi", calculatorName: "Yhteiskuntaoppi", category: "otherSubject"}, 
//   {name: "Historia", diplomaName: "Historia", calculatorName: "Historia", category: "otherSubject"}, 
//   {name: "Filosofia", diplomaName: "Filosofia", calculatorName: "Filosofia", category: "otherSubject"}, 
//   {name: "Psykologia", diplomaName: "Psykologia", calculatorName: "Psykologia", category: "otherSubject"}, 
//   {name: "Kemia", diplomaName: "Kemia", calculatorName: "Kemia", category: "otherSubject"}, 
//   {name: "Fysiikka", diplomaName: "Fysiikka", calculatorName: "Fysiikka", category: "otherSubject"}, 
//   {name: "Biologia", diplomaName: "Biologia", calculatorName: "Biologia", category: "otherSubject"}, 
//   {name: "Terveystieto", diplomaName: "Terveystieto", calculatorName: "Terveystieto", category: "otherSubject"}, 
//   {name: "Maantiede", diplomaName: "Maantiede", calculatorName: "Maantiede", category: "otherSubject"}, 
//   {name: "Uskonto - evankelisluterilainen", diplomaName: "Uskonto - evankelisluterilainen", calculatorName: "Uskonto evankelisluterilainen", category: "otherSubject"}, 
//   {name: "Uskonto - ortodoksinen", diplomaName: "Uskonto - ortodoksinen", calculatorName: "Uskonto ortodoksinen", category: "otherSubject"}, 
//   {name: "Elämänkatsomustieto", diplomaName: "Elämänkatsomustieto", calculatorName: "Elämänkatsomustieto", category: "otherSubject"}
// ]

// const matriculationSubjects = {
//   motherLanguages: motherLanguages,
//   mathematics: mathematics,
//   longLanguages: longLanguages,
//   middleLanguages: middleLanguages,
//   shortLanguages: shortLanguages,
//   otherSubjects: otherSubjects
// }

const matriculationSubjects = [
  {
    name: 'Suomi',
    diplomaName: 'Äidinkieli - suomi',
    calculatorName: 'Äidinkieli Suomi',
    category: 'motherLanguage'
  },
  {
    name: 'Ruotsi',
    diplomaName: 'Äidinkieli - ruotsi',
    calculatorName: 'Äidinkieli Ruotsi',
    category: 'motherLanguage'
  },
  {
    name: 'Suomi toisena kielenä',
    diplomaName: 'Suomi toisena kielenä',
    calculatorName: 'Suomi 2',
    category: 'motherLanguage'
  },
  {
    name: 'Ruotsi toisena kielenä',
    diplomaName: 'Ruotsi toisena kielenä',
    calculatorName: 'Ruotsi 2',
    category: 'motherLanguage'
  },
  {
    name: 'Pohjoissaame',
    diplomaName: 'Äidinkieli - pohjoissaame',
    calculatorName: 'Äidinkieli Pohjoissaame',
    category: 'motherLanguage'
  },
  {
    name: 'Koltansaame',
    diplomaName: 'Äidinkieli - koltansaame',
    calculatorName: 'Äidinkieli Koltansaame',
    category: 'motherLanguage'
  },
  {
    name: 'Inarinsaame',
    diplomaName: 'Äidinkieli - inarinsaame',
    calculatorName: 'Äidinkieli Inarinsaame',
    category: 'motherLanguage'
  },
  {
    name: 'Pitkä matematiikka',
    diplomaName: 'Pitkä matematiikka',
    calculatorName: 'Matematiikka pitkä',
    category: 'mathematics'
  },
  {
    name: 'Lyhyt matematiikka',
    diplomaName: 'Lyhyt matematiikka',
    calculatorName: 'Matematiikka lyhyt',
    category: 'mathematics'
  },
  {
    name: 'Englanti',
    diplomaName: 'Englanti - pitkä',
    calculatorName: 'Englanti pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Toinen kotimainen kieli - ruotsi',
    diplomaName: 'Toinen kotimainen kieli, ruotsi - pitkä',
    calculatorName: 'Ruotsi pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Toinen kotimainen kieli - suomi',
    diplomaName: 'Toinen kotimainen kieli, suomi - pitkä',
    calculatorName: 'Suomi pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Venäjä',
    diplomaName: 'Venäjä - pitkä',
    calculatorName: 'Venäjä pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Saksa',
    diplomaName: 'Saksa - pitkä',
    calculatorName: 'Saksa pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Ranska',
    diplomaName: 'Ranska - pitkä',
    calculatorName: 'Ranska pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Espanja',
    diplomaName: 'Espanja - pitkä',
    calculatorName: 'Espanja pitkä',
    category: 'longLanguage'
  },
  {
    name: 'Toinen kotimainen kieli - ruotsi',
    diplomaName: 'Toinen kotimainen kieli, ruotsi - keskipitkä',
    calculatorName: 'Ruotsi keskipitkä',
    category: 'middleLanguage'
  },
  {
    name: 'Toinen kotimainen kieli - suomi',
    diplomaName: 'Toinen kotimainen kieli, suomi - keskipitkä',
    calculatorName: 'Suomi keskipitkä',
    category: 'middleLanguage'
  },
  {
    name: 'Venäjä',
    diplomaName: 'Venäjä - lyhyt',
    calculatorName: 'Venäjä lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Saksa',
    diplomaName: 'Saksa - lyhyt',
    calculatorName: 'Saksa lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Ranska',
    diplomaName: 'Ranska - lyhyt',
    calculatorName: 'Ranska lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Englanti',
    diplomaName: 'Englanti - lyhyt',
    calculatorName: 'Englanti lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Espanja',
    diplomaName: 'Espanja - lyhyt',
    calculatorName: 'Espanja lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Italia',
    diplomaName: 'Italia - lyhyt',
    calculatorName: 'Italia lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Latina',
    diplomaName: 'Latina - lyhyt',
    calculatorName: 'Latina lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Portugali',
    diplomaName: 'Portugali - lyhyt',
    calculatorName: 'Portugali lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Pohjoissaame',
    diplomaName: 'Pohjoissaame - lyhyt',
    calculatorName: 'Pohjoissaame lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Koltansaame',
    diplomaName: 'Koltansaame - lyhyt',
    calculatorName: 'Koltansaame lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Inarinsaame',
    diplomaName: 'Inarinsaame - lyhyt',
    calculatorName: 'Inarinsaame lyhyt',
    category: 'shortLanguage'
  },
  {
    name: 'Yhteiskuntaoppi',
    diplomaName: 'Yhteiskuntaoppi',
    calculatorName: 'Yhteiskuntaoppi',
    category: 'otherSubject'
  },
  {
    name: 'Historia',
    diplomaName: 'Historia',
    calculatorName: 'Historia',
    category: 'otherSubject'
  },
  {
    name: 'Filosofia',
    diplomaName: 'Filosofia',
    calculatorName: 'Filosofia',
    category: 'otherSubject'
  },
  {
    name: 'Psykologia',
    diplomaName: 'Psykologia',
    calculatorName: 'Psykologia',
    category: 'otherSubject'
  },
  {
    name: 'Kemia',
    diplomaName: 'Kemia',
    calculatorName: 'Kemia',
    category: 'otherSubject'
  },
  {
    name: 'Fysiikka',
    diplomaName: 'Fysiikka',
    calculatorName: 'Fysiikka',
    category: 'otherSubject'
  },
  {
    name: 'Biologia',
    diplomaName: 'Biologia',
    calculatorName: 'Biologia',
    category: 'otherSubject'
  },
  {
    name: 'Terveystieto',
    diplomaName: 'Terveystieto',
    calculatorName: 'Terveystieto',
    category: 'otherSubject'
  },
  {
    name: 'Maantiede',
    diplomaName: 'Maantiede',
    calculatorName: 'Maantiede',
    category: 'otherSubject'
  },
  {
    name: 'Uskonto - evankelisluterilainen',
    diplomaName: 'Uskonto - evankelisluterilainen',
    calculatorName: 'Uskonto evankelisluterilainen',
    category: 'otherSubject'
  },
  {
    name: 'Uskonto - ortodoksinen',
    diplomaName: 'Uskonto - ortodoksinen',
    calculatorName: 'Uskonto ortodoksinen',
    category: 'otherSubject'
  },
  {
    name: 'Elämänkatsomustieto',
    diplomaName: 'Elämänkatsomustieto',
    calculatorName: 'Elämänkatsomustieto',
    category: 'otherSubject'
  }
]

export default matriculationSubjects


// const matriculationSubjects = [
//   {
//     name: 'Äidinkieli',
//     extra: {
//       name: 'kieli',
//       options: ['Suomi', 'Ruotsi', 'Inarinsaame', 'Pohjoissaame', 'Koltansaame']
//     },
//     type: 'motherLanguage'
//   },
//   {
//     name: 'Suomi toisena kielenä',
//     extra: false,
//     type: 'motherLanguage',
//     calculatorName: 'Suomi 2'
//   },
//   {
//     name: 'Ruotsi toisena kielenä',
//     extra: false,
//     type: 'motherLanguage',
//     calculatorName: 'Ruotsi 2'
//   },
//   {
//     name: 'Filosofia',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Fysiikka',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Kemia',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Historia',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Maantiede',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Psykologia',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Terveystieto',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Uskonto',
//     extra: {
//       name: 'suuntaus',
//       options: ['evankelisluterilainen', 'ortodoksinen']
//     },
//     type: 'real'
//   },
//   {
//     name: 'Elämänkatsomustieto',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Yhteiskuntaoppi',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Biologia',
//     extra: false,
//     type: 'real'
//   },
//   {
//     name: 'Englanti',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Saksa',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Ranska',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Espanja',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Venäjä',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Toinen kotimainen kieli - Suomi',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'keskipitkä']
//     },
//     type: 'secondOfficialLanguage',
//     calculatorName: 'Suomi'
//   },
//   {
//     name: 'Toinen kotimainen kieli - Ruotsi',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'keskipitkä']
//     },
//     type: 'secondOfficialLanguage',
//     calculatorName: 'Ruotsi'
//   },
//   {
//     name: 'Italia',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Portugali',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Latina',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Pohjoissaame',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Inarinsaame',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Koltansaame',
//     extra: {
//       name: 'pituus',
//       options: ['lyhyt']
//     },
//     type: 'language'
//   },
//   {
//     name: 'Matematiikka',
//     extra: {
//       name: 'pituus',
//       options: ['pitkä', 'lyhyt']
//     },
//     type: 'mathematics'
//   }
// ]
