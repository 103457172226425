import React, { useEffect, useState } from 'react'
import {
  Col, Row, Container
} from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import InfoDashboard from './InfoDashboard'
import styles from './Header.module.css'
import DiplomaInput from '../DiplomaInput'
import GpaInput from '../GpaInput'
import { useTranslation } from 'react-i18next'
import HoasDesktop from '../../../static/images/HOAS_desktop.png'
import HoasMobile from '../../../static/images/HOAS_mobile.png'
import { MOBILE_BREAKPOINT } from '../../../constants/constants'

const Header = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const isMobile = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const [page, setPage] = useState('university')
  const [mobile, setIsMobile] = useState(isMobile())

  const handleWindowResize = () => {
    setIsMobile(isMobile())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/yliopistoon') {
      setPage('university')
    }
    if (location.pathname === '/ammattikorkeaan') {
      setPage('amk')
    }
    if (location.pathname === '/lukioon') {
      setPage('highschool')
    }
  }, [location])

  return(
    <div className={`w-100 ${styles.headerWrapper}`}>
      <Container className={styles.headerContainer}>
        <Row className={styles.alignItems}>
          <Col md={6}>
            <h1 className="mb-3">{t(`pageBuilder.header.header.${page}`)}</h1>
            <p>{t(`pageBuilder.header.description1.${page}`)} </p>
            <p className="mb-4">{t(`pageBuilder.header.description2.${page}`)} </p>
            {location.pathname === '/lukioon' ? <GpaInput /> : <DiplomaInput /> }
          </Col>
          <Col md={6} className={styles.infoAlign}>
            <InfoDashboard />
          </Col>
        </Row>
        {location.pathname === '/yliopistoon' &&
          <div className={styles.ad}>
            <a target="_blank" rel="noopener noreferrer" href="https://hoas.fi/alueet/" className={styles.fullWidth}>
              <img src={mobile ? HoasMobile : HoasDesktop} className={styles.image} />
            </a>
          </div>
        }

      </Container>
    </div>
  )
}

export default Header
