import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import styles from './HoverElement.module.css'
import { MOBILE_BREAKPOINT, QUARTER_SECONDS } from '../../../constants/constants'
import PropTypes from 'prop-types'

const DiplomaHoverElement = props => {

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const [showText, setShowText] = useState(false)
  const [show, setShow] = useState(true)
  const [mobile, setWindowSize] = useState(getWindowSize())

  let timeout = null

  const mouseEnterTimeout = () => {
    if (!mobile) {
      timeout = setTimeout(() => {
        setShowText(true)
        const element = document.getElementById('fading-text')
        element.style.opacity = 1
        element.style.transition = '0.5s all'
      }, QUARTER_SECONDS)
    }
  }

  const mouseExit = () => {
    if (!mobile) {
      if (timeout !== null) {
        clearTimeout(timeout)
        timeout = null
      }
      const element = document.getElementById('fading-text')
      element.style.opacity = 0
      setShowText(false)
    }
  }
  
  const location = useLocation()
  
  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  
  useEffect(() => {
    if (location.pathname === '/lukioon') {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [location])

  return (
    <>
      {show &&
        <div onMouseEnter={() => mouseEnterTimeout()} onMouseLeave={() => mouseExit()} onClick={props.handleShow}>
          <div className={styles.hoverElement}>
            <Row className={`mx-0 ${styles.alignCenter}`}>
              <Col className="px-0" xs={4}>
                <div className={styles.hoverElementButton}>
                  <i className={`${styles.hoverIcon} bi bi-pencil-fill`}></i>
                </div>
              </Col>
              <Col className="px-0" xs={8}>
                <div id="fading-text" className={styles.text}>
                  {showText && <p className="mb-0">Muokkaa todistustasi</p>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      }
    </>
  )
}

DiplomaHoverElement.propTypes = {
  handleShow: PropTypes.func.isRequired
}

export default DiplomaHoverElement
