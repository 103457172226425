import React, { useState, useEffect } from 'react'
import { Row, Container} from 'react-bootstrap'
import styles from './MobileContentHighschool.module.css'
import {
  YEARS_HIGHSCHOOL, THOUSAND, TEN
} from '../../../../../../constants/constants'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SpecialHighSchool from '../../HighschoolContent/SpecialHighschool'
import { useTranslation } from 'react-i18next'

const MobileContentHighschool = ({ program }) => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.listing'})
  const [theme, setTheme] = useState('')
  const [icon, setIcon] = useState('')
  const [text, setText] = useState('')

  const diplomNotChosen = t('highschool.diplomNotChosen')
  const gotInText = t('gotInText', {year: YEARS_HIGHSCHOOL[0]})
  const notInText = t('notInText', {year: YEARS_HIGHSCHOOL[0]})
  const missingResults = `Pisteet puuttuvat vuodelta ${YEARS_HIGHSCHOOL[0]}`

  const gpa = useSelector(state => state.diplom.gpa)
  
  useEffect(() => {
    if (gpa === null) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(diplomNotChosen)
    } else if (!program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`]) {
      setTheme(styles.diplomNotSet)
      setIcon('question-circle-fill')
      setText(missingResults)
    } else if (gpa >= program.minimumPoints[0][`year_${YEARS_HIGHSCHOOL[0]}`]) {
      setTheme(styles.gotIn)
      setIcon('check-circle-fill')
      setText(gotInText)
    } else {
      setTheme(styles.notIn)
      setIcon('x-circle-fill')
      setText(notInText)
    }
  }, [])

  return (
    <Container className={styles.cardContent}>
      <div className="mb-3">
        <Row className={styles.programName}>
          <h2>{program.name}</h2>
        </Row>
        <Row>
          <div className={styles.infoRow}>
            <div className={styles.subheader}>
              <i className="bi bi-geo-alt me-2"></i>
              <p className="mb-0">{program.school.location.name}</p>
            </div>
          </div>
        </Row>
        {program.special
          ?
          <SpecialHighSchool />
          :
          <>
            <Row>
              <div className={`${styles.iconRow} ${theme}`}>
                <i className={`bi bi-${icon} ${styles.headerIcon}`}></i>
                <p className="mb-0">{text}</p>
              </div>
            </Row>
            <Row className={styles.previousYearsInfo}>
              <h3>{t('tableTitle')}</h3>
            </Row>
            {
              YEARS_HIGHSCHOOL.map(year => {
                const points = program.minimumPoints[0][`year_${year}`]
                const gotIn = gpa >= points
                const margin = Math.round((gpa - points) / points * THOUSAND) / TEN
                return(
                  <Row key={year}>
                    <div className={styles.year}>
                      <div>
                        <p className="mb-0 ms-2">{t('year')}</p>
                      </div>
                      <div className={styles.yearRow}>
                        <h4 className="ms-2">{year}</h4>
                      </div>
                      <div className={styles.white}>
                        <div className={styles.contentCol}>
                          <p className="mb-0">{t('gotIn')}</p>
                        </div>
                        <div className={styles.iconCol}>
                          <i className={`bi bi-${(gpa !== null && points) ? (gotIn ? `check-circle-fill ${styles.gotIn}` : `x-circle-fill ${styles.notIn}`) : `question-circle-fill ${styles.diplomNotSet}`} ${styles.icon}`}></i>
                        </div>
                      </div>
                      <div className={styles.gray}>
                        <div className={styles.contentCol}>
                          <p className="mb-0">{t('highschool.requiredGPA')}</p>
                        </div>
                        <div className={points ? styles.pointCol : styles.diplomNotSet}>
                          {points ? points : 'Keskiarvo puuttuu'}
                        </div>
                      </div>
                      <div className={styles.white}>
                        <div className={styles.contentCol}>
                          <p className="mb-0">{t('margin')}</p>
                        </div>
                        <div className={styles.textCol}>
                          <div className={(gpa !== null && points) ? (gotIn ? styles.gotIn : styles.notIn) : styles.diplomNotSet}>
                            {(gpa !== null && points) ? `${margin} %` : (points ? t('highschool.noGPA') : 'Keskiarvo puuttuu')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                )
              })
            }
          </>
        }
      </div>
    </Container>
  )
}

MobileContentHighschool.propTypes = {
  program: PropTypes.object.isRequired
}

export default MobileContentHighschool
