import {
  changeFormat, initialFormat, validateChoice, validateFormat
} from '../utils/validate'
import { calculateAMKResults, fetchAMKs } from './AMKReducer'
import { setDiplom } from './diplomReducer'
// import { programSort } from './filterReducer'
import { fetchHighSchools } from './highSchoolReducer'
import { calculateUniversities, fetchUniversities } from './universitiesReducer'


const initializeDiplom = () => {
  const toReturn = {
    diplom: [],
    gpa: null
  }
  const subjectString = localStorage.getItem('DiplomSubjects')
  const gpa = localStorage.getItem('DiplomGPA')
  if(subjectString) {
    try {
      const parsed = JSON.parse(subjectString)
      let format = initialFormat
      let writtenSubjects = []
      let isValid = true
      parsed.forEach(subject => {
        const validationResult = validateChoice(subject, writtenSubjects)
        if(!validationResult.valid) {
          isValid = false
        } else {
          format = changeFormat(subject, true, format)
          writtenSubjects = [...writtenSubjects, subject]
        }
      })

      if(isValid) {
        const validationResult = validateFormat(format)
        if(validationResult.valid) {
          const hasGrades = writtenSubjects.filter(item => item.grade === null).length === 0
          if(hasGrades) {
            toReturn.diplom = writtenSubjects
          } else {
            localStorage.removeItem('DiplomSubjects')
          }
        } else {
          localStorage.removeItem('DiplomSubjects')
        }
      } else {
        localStorage.removeItem('DiplomSubjects')
      }
    } catch (error) {
      localStorage.removeItem('DiplomSubjects')
    }
  }


  if(gpa) {
    try {
      const parsed = parseFloat(JSON.parse(gpa))
      if(!isNaN(parsed) && parsed >= 4 && parsed <= 10) {
        toReturn.gpa = parsed
      } else {
        localStorage.removeItem('DiplomGPA')
      }
    } catch (error) {
      localStorage.removeItem('DiplomGPA')
    }
  }

  return toReturn
}

const initializeAMKs = diplom => async dispatch => {
  dispatch(fetchAMKs())
  if(diplom.length !== 0) {
    const subjectsToCalculator = diplom.map(subject => [subject.calculatorName, subject.grade])
    dispatch(calculateAMKResults(subjectsToCalculator))
  }
}

const initializeUniversities = diplom => async dispatch => {
  dispatch(fetchUniversities())
  if(diplom.length !== 0) {
    const subjectsToCalculator = diplom.map(subject => [subject.calculatorName, subject.grade])
    dispatch(calculateUniversities(subjectsToCalculator))
  }
}

export const initializeAll = () => async dispatch => {
  // Get high schools. This does not depend on other operations
  dispatch(fetchHighSchools())
    
  // Get highschool diplom from local storage and save it into state
  const diplom = initializeDiplom()
  dispatch(setDiplom(diplom))
    
  // Start retrieving universities and AMKs right after the highschool diplom have been defined
  dispatch(initializeUniversities(diplom.diplom))
  dispatch(initializeAMKs(diplom.diplom))
}
