import axios from 'axios'
import { AMKS_URL, BASE_URL } from '../constants/constants'


const getAMKPrograms = async () => {
  const response = await axios.get(BASE_URL + AMKS_URL + 'programs/')
  return response.data
}

const calculateResults = async subjects => {
  const response = await axios.post(BASE_URL + AMKS_URL + 'calculate/', subjects)
  return response.data
}


export default {
  getAMKPrograms,
  calculateResults
}
