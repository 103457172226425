import React from 'react'
import Hamburger from './Hamburger'
import { Link } from 'react-router-dom'
import styles from './MobileNavigation.module.css'
import korkeakouluunLogo from '../static/images/korkeakouluun_logo.png'

const MobileNavigation = () => (
  <div>
    <div className={`justify-content-between w-100 ${styles.navbar}`}>
      <Link to='/'>
        <img
          src={korkeakouluunLogo}
          height="40"
          alt="Korkeakouluun logo"
        />
      </Link>
      <Hamburger />
    </div>
  </div>

)

export default MobileNavigation
