import React, { useEffect, useState } from 'react'
import {
  Button, Row, Container
} from 'react-bootstrap'
import CustomDropdown from './CustomDropdown'
import styles from './Filters.module.css'
import { MOBILE_BREAKPOINT, NAV_BREAKPOINT } from '../../../constants/constants'
import CustomCheckbox from './CustomCheckbox'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { resetUniversityFilters, setUniversityFilters } from '../../../reducers/universitiesReducer'
import { resetAMKFilters, setAMKFilters } from '../../../reducers/AMKReducer'
import { resetHighSchoolFilters, setHighSchoolFilters } from '../../../reducers/highSchoolReducer'
import { createSearchEvent } from '../../../utils/analytics'
import { useTranslation } from 'react-i18next'


const Filters = () => {
  const { t } = useTranslation('translation', {keyPrefix: 'pageBuilder.filters'})
  const location = useLocation()
  const dispatch = useDispatch()

  const programs = useSelector(state => {
    switch(location.pathname){
    case '/yliopistoon':
      return state.universities.programs
    case '/ammattikorkeaan':
      return state.amks.programs
    case '/lukioon':
      return state.highSchools.programs
    default:
      return state.universities.programs
    }
  })

  const getWindowSize = () => {
    const {innerWidth} = window
    return innerWidth <= MOBILE_BREAKPOINT
  }

  const navCollapsed = () => {
    const {innerWidth} = window
    return innerWidth <= NAV_BREAKPOINT
  }

  const defaultFilters = {
    text: '',
    location: [],
    school: [],
    gotIn: false,
    diplom: false
  }

  const [textInput, setTextInput] = useState('')
  const [closeButton, setCloseButton] = useState(false)
  const [mobile, setWindowSize] = useState(getWindowSize())
  const [underNavCollapsed, setUnderNavCollapsed] = useState(navCollapsed())
  const [filters, setFilters] = useState(defaultFilters)
  const [locations, setLocations] = useState([])
  const [schools, setSchools] = useState([])
  const [dropdownSchoolTitle, setDropdownSchoolTitle] = useState('')
  const [disabled, setDisabled] = useState(false)

  const gpa = useSelector(state => state.diplom.gpa)

  const savedDiplom = useSelector(state => state.diplom.diplom)

  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
    setUnderNavCollapsed(navCollapsed())
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/lukioon') {
      const allLocations = [...new Set(programs.map(program => program.school.location.name))]
      const allSchools = [...new Set(programs.map(program => program.school.name))]
      setLocations(allLocations)
      setSchools(allSchools)
      setDropdownSchoolTitle('highschool')
    } else {
      const allLocations = [...new Set(programs.map(program => program.location.name))]
      const allSchools = [...new Set(programs.map(program => program.school.name))]
      setLocations(allLocations)
      setSchools(allSchools)
      if (location.pathname === '/yliopistoon') {
        setDropdownSchoolTitle('university')
      } else setDropdownSchoolTitle('amk')
    }
    
  }, [programs])

  useEffect(() => {
    setFilters(defaultFilters)
    dispatch(resetUniversityFilters())
    dispatch(resetAMKFilters())
    dispatch(resetHighSchoolFilters())
    setTextInput('')
    setCloseButton(false)
  }, [location])

  useEffect(() => {
    setDisabled((savedDiplom.length === 0 && location.pathname !== '/lukioon') || (gpa === null && location.pathname === '/lukioon'))
  }, [
    location,
    savedDiplom,
    gpa
  ])

  const handleFilter = selectedFilters => {
    switch(location.pathname) {
    case '/yliopistoon':
      dispatch(setUniversityFilters(selectedFilters))
      break
    case '/ammattikorkeaan':
      dispatch(setAMKFilters(selectedFilters))
      break
    case '/lukioon':
      dispatch(setHighSchoolFilters({
        gpa,
        filters: selectedFilters
      }))
      break
    default:
      break
    }
  }

  const handleSearchButton = () => {
    createSearchEvent(textInput.toLowerCase())
    const newFilters = {
      ...filters,
      text: textInput
    }
    handleFilter(newFilters)
    setFilters(newFilters)
    document.getElementById('resetButton').scrollIntoView()
  }

  const handleTextField = event => {
    setTextInput(event.target.value)
    if (event.target.value === '') {
      setCloseButton(false)
    } else setCloseButton(true)
  }

  const handleEnter = e => {
    if(e.key === 'Enter'){
      e.preventDefault()
      handleSearchButton()
    }
  }

  const handleDropDownSave = (choises, name) => {
    const newFilters = {
      ...filters,
      [name]: choises
    }
    handleFilter(newFilters)
    setFilters(newFilters)
  }

  const handleCheckboxSave = name => {
    if (name !== 'gotIn' || (name === 'gotIn' && !disabled)) {
      const newFilters = {
        ...filters,
        [name]: !filters[name]
      }
      handleFilter(newFilters)
      setFilters(newFilters)
    }
  }

  const handleTextReset = () => {
    const newFilters = {
      ...filters,
      text: ''
    }
    handleFilter(newFilters)
    setFilters(newFilters)
    setTextInput('')
    setCloseButton(false)
  }

  const handleReset = () => {
    switch(location.pathname) {
    case '/yliopistoon':
      dispatch(resetUniversityFilters())
      break
    case '/ammattikorkeaan':
      dispatch(resetAMKFilters())
      break
    case '/lukioon':
      dispatch(resetHighSchoolFilters())
      break
    default:
      break
    }
    setFilters(defaultFilters)
    setTextInput('')
    setCloseButton(false)
  }

  return (
    <div className={`w-100 ${styles.filterWrapper}`}>
      <Container>
        <div className={styles.element}>
          <Row className="mb-3">
            <h3>{t('title')}</h3>
          </Row>
          <Row className={styles.alignCenter}>
            <i className={`bi bi-search ${styles.searchColor}`}></i>
            <input
              type='text'
              placeholder={underNavCollapsed ? t('placeholder.mobile') : t('placeholder.desktop')}
              value={textInput}
              onChange={event => handleTextField(event)}
              onKeyDown={handleEnter}
              className={styles.search}
            />
            {!mobile &&
              <div className={styles.buttonContainer}>
                <Button className={`button-primary ${styles.searchButton}`} onClick={handleSearchButton}>{t('search')}</Button>
              </div>
            }
            {
              closeButton &&
              <i className={`bi bi-x-circle-fill ${styles.closeButton}`} onClick={handleTextReset}></i>
            }
          </Row>
          <Row className={mobile ? 'mt-2' : 'mt-4'}>
            <CustomDropdown options={locations} chosen={filters.location} name={'location'} title={t('dropdown.locationTitle')} mobile={mobile} handleSave={handleDropDownSave} disabled={false} />
            <CustomDropdown options={schools} chosen={filters.school} name={'school'} title={t(`dropdown.schoolTitle.${dropdownSchoolTitle}`)} mobile={mobile} handleSave={handleDropDownSave} disabled={false} />
          </Row>
          <h4 className="mt-4">{t('extraChoises')}</h4>
          <div className="mt-3">
            <CustomCheckbox checked={filters.gotIn} name={'gotIn'} label={t('onlyGotIn')} handleClick={handleCheckboxSave} disabled={disabled}/>
          </div>
          <div className="mt-2">
            <CustomCheckbox checked={filters.diplom} name={'diplom'} label={location.pathname === '/lukioon' ? t('onlyDiplom.highschool') : t('onlyDiplom.other')} handleClick={handleCheckboxSave} disabled={false} />
          </div>
          {mobile && <Button className={`button-primary ${styles.searchButton}`} onClick={handleSearchButton}>{t('search')}</Button>}
          <Button className={`button-secondary ${!mobile && 'mt-3'}`} onClick={handleReset} id="resetButton">{t('reset')}</Button>
        </div>
      </Container>
    </div>
  )
}


export default Filters
